/*
 *  Created: @themepixels
 *  Website: http://themepixels.com/
 * 
 *  ---------------------------------------
 *  ---------- TABLE OF CONTENTS ----------
 *  ---------------------------------------
 *  
 *  1.  FILE IMPORTS
 *  2.  RESET STYLES
 *  3.  HEADER PANEL
 *          3.1     HEADER LEFT
 *          3.2     HEADER RIGHT
 *  4.  LEFT PANEL
 *          4.1     NAVIGATION STYLES
 *          4.2     INFO SUMMARY
 *  5.  MAIN PANEL
 *          5.1     DASHBOARD
 *  6.  FORM STYLES
 *          6.1     FORM WIZARD STYLES
 *  7.  UI ELEMENTS
 *  8.  EMAIL
 *  9.  TABLES
 *  10. PAGES
 *          10.1    CALENDAR
 *          10.2    MEDIA MANAGER
 *          10.3    PROFILE
 *          10.4    BLOG LIST
 *          10.5    BLOG SINGLE
 *          10.6    PEOPLE DIRECTORY
 *          10.7    NOT FOUND PAGE
 *          10.8    SEARCH RESULTS
 *          10.9    INVOICE
 *          10.10   TIMELINE
 *          10.11   LOCKED SCREEN
 *          10.12   SIGN IN
 *          10.13   SIGN UP
 *  11. LEFT PANEL COLLAPSED
 *  12. RIGHT PANEL STYLES
 *  13. HORIZONTAL MENU
 *  14. FIXED WIDTH
 *  15. MODALS
 *  16. BUG TRACKER
 *  17. ADDITIONAL STYLES
 *          17.1    DROPDOWN
 *          17.2    ICON
 *          17.3    TIME PICKER
 *          17.4    COLOR PICKER
 *          17.5    STICKY HEADER
 *          17.6    CUSTOM STYLES
 *  18. MEDIA QUERIES
 *  
 */





/********** 1. FILE IMPORTS **********/
/*************************************/

@import url('bootstrap.min.css');/*
@import url('font-awesome.min.css');
@import url('bootstrap-override.css');
@import url('weather-icons.min.css');
@import url('jquery-ui-1.10.3.css');
@import url('animate.min.css');
@import url('animate.delay.css');
@import url('toggles.css');
@import url('select2.css');
@import url('lato.css');
@import url('roboto.css');*/


/********** 2. RESET STYLES **********/
/*************************************/

body {
    background: #1d2939;
    line-height: 21px;
    color: #636e7b;
}

body.notfound,
body.signin {
    background: #e4e7ea;
}

input,select,textarea {
    font-family: 'LatoRegular', 'Lucida Grande', 'Lucida Sans Unicode', Helvetica, sans-serif !important;
    color: #636e7b;
}

a:focus, a:active { outline: none; }

h1,h2,h3,h4,h5 {
}

strong {
    font-family: 'LatoBold';
    font-weight: normal;
}

/* preloader */
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #e4e7ea; 
    z-index: 10000; 
}

#status {
    width: 30px;
    height: 30px;
    position: absolute;
    left: 50%; 
    top: 50%;
    margin: -15px 0 0 -15px;
    font-size: 32px;
}



/********** 3. HEADER PANEL **********/
/*************************************/


.headerbar {
    border-left: 1px solid #eee;
    background: #fff;
}

.headerbar::after {
    clear: both;
    display: block;
    content: '';
}


/***** 3.1 HEADER LEFT *****/

.menutoggle {
    float: left;
    width: 50px;
    height: 50px;
    font-size: 22px;
    cursor: pointer;
    float: left;
    color: #1d2939;
    border-right: 1px solid #eee;
    -moz-transition: all 0.2s ease-out 0s;
    -webkit-transition: all 0.2s ease-out 0s;
    transition: all 0.2s ease-out 0s;
    display: none;
}

.menutoggle:hover {
    color: #1d2939;
    background-color: #f7f7f7;
}

.menutoggle .fa,
.menutoggle .glyphicon,
.menutoggle .iconfont {
    margin: 14px 0 0 15px;
}

.menutoggle > input{
    display: none;
}

.menutoggle .nav-bracket{
    display: none;
    width: 80%;
    z-index: 999;
    position:absolute;
    background: #1d2939;
    border-radius:5px;
}

.menutoggle .nav-bracket .children li{
    background: #1D2939;
    border-radius:5px;
}

.menutoggle > input:checked ~ ul{
    display: inline;
}

.menutoggle > input:checked ~ label .icon-icon-test:before{
    content: "\e69e";
}

.menutoggle .nav-bracket{
    margin-top: 50px;
    font-size: 14px;
}

.searchform input {
    width: 50%;/*
    background: #fff url(../images/icon-search.png) no-repeat 95% center;*/
    float: left;
    padding: 18px 20px;
    border: 0;
    border-right: 1px solid #eee;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    height: 50px;
    font-size: 14px;
}

.searchform input:focus {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #ddd;
}

.searchform-left {
    margin-top: 10px;
    margin-bottom: 20px;
}

.searchform-left .form-control {
    border: 0;
    background: #131b26;
}

.header-left {
    float: left;
}



/***** 3.2 HEADER RIGHT *****/

.header-right {
    float: right;
}

.headermenu {
    list-style: none;
    margin: 0;
    padding: 0;
}

.headermenu:after {
    clear: both;
    display: block;
    content: '';
}

.headermenu > li {
    display: inline-block;
    float: left;
    border-left: 1px solid #eee;
    position: relative;
}

.headermenu .dropdown-toggle {
    padding: 12px 10px;
    border-color: #fff;
    background: #fff;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    border: 0;
}

.headermenu .dropdown-toggle:hover,
.headermenu .dropdown-toggle:focus,
.headermenu .dropdown-toggle:active,
.headermenu .dropdown-toggle.active,
.headermenu .open .dropdown-toggle.dropdown-toggle {
    background: #f9f9f9;
    color: #333;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.headermenu .dropdown-toggle img {
    vertical-align: middle;
    margin-right: 5px;
    width: 26px;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
}

.headermenu .dropdown-toggle .caret {
    margin-left: 5px;
}

.headermenu .dropdown-menu {
    border: 0;
    margin-top: 0px;
    margin-right: -1px;
    -moz-border-radius: 2px 0 2px 2px;
    -webkit-border-radius: 2px 0 2px 2px;
    border-radius: 2px 0 2px 2px;
    -moz-box-shadow: 3px 3px 0 rgba(12,12,12,0.05);
    -webkit-box-shadow: 3px 3px 0 rgba(12,12,12,0.05);
    box-shadow: 3px 3px 0 rgba(12,12,12,0.05);
    padding: 5px;
}

.headermenu .dropdown-menu:after {
    border-bottom: 6px solid #1d2939;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    content: "";
    display: inline-block;
    right: 17px;
    position: absolute;
    top: -6px;
}

.headermenu .dropdown-grid .grid-list {
    padding: 20px;
}

.headermenu .dropdown-menu li {
    display: block;
    margin: 0;
    float: none;
    background: none;
    padding: 15px 5px;
}

.headermenu .dropdown-menu-usermenu li {
    padding: 0;
}

.headermenu .dropdown-menu-usermenu:after {
    right: 95px;
}

.headermenu .dropdown-menu li a {
    color: #94989d;
    font-size: 13px;
    padding: 7px 10px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-transition: all 0.2s ease-out 0s;
    -webkit-transition: all 0.2s ease-out 0s;
    transition: all 0.2s ease-out 0s;
}

.headermenu .dropdown-menu li a:hover {
    background: #1caf9a;
    color: #fff;
}

.headermenu .dropdown-menu li i {
    font-size: 11px;
    margin-right: 5px;
}

.headermenu .dropdown-menu-head {
    padding: 0;
    min-width: 300px;
}

.headermenu .tp-icon {
    padding: 12px 15px;
    height: 50px;
    font-size: 16px;
    background: #fff;
    color: #333;
    border-color: #fff;
    -moz-transition: all 0.2s ease-out 0s;
    -webkit-transition: all 0.2s ease-out 0s;
    transition: all 0.2s ease-out 0s;
}

.headermenu .tp-icon + .dropdown-menu:after {
    right: 17px;
}

.headermenu .chat-icon {
    border-right: 0;
}

.headermenu .dropdown-menu-usermenu {
    background: #1d2939;
    min-width: 200px;
}

.headermenu .dropdown-menu-head ul {
    border: 1px solid #ddd;
    border-top: 0;
    padding: 0 10px;
}

.headermenu .dropdown-menu-head li a {
    color: #333;
    padding: 0;
    opacity: 1;
}

.headermenu .dropdown-menu-head li a:hover {
    background: none;
    color: #428BCA;
    text-decoration: none;
}

.headermenu .btn-group {
    margin-bottom: 0;
}

.headermenu .btn-group input{
    display: none;
}

.headermenu .btn-group input:checked ~ ul{
    display: inline;
}

.headermenu .btn-group input:checked ~ div{
    display: inline;
}

.dropdown-list li {
    padding: 15px;
    overflow: hidden;
    border-bottom: 1px solid #eee;
}

.dropdown-list li:last-child {
    border-bottom: 0;
}

.dropdown-list .thumb {
    width: 36px;
    float: left;
}

.dropdown-list .thumb img {
    width: 100%;
    display: block;
    vertical-align: middle;
}

.dropdown-list .desc {
    margin-left: 45px;
    display: block;
}

.dropdown-list .desc h5 {
    font-size: 13px;
    margin-top: 7px;
}

.dropdown-list li:last-child {
    padding: 7px 5px;
}

.dropdown-list li .badge {
    float: right;
}

.user-list {
    width: 300px;
}

.gen-list li a {
    display: block;
}

.gen-list li a:hover {
    color: #333 !important;
    text-decoration: none;
}

.gen-list li .name {
    font-size: 13px;
    font-family: 'LatoBold','Lucida Grande', 'Lucida Sans Unicode', Helvetica, sans-serif;
    line-height: 21px;
    display: block;
}

.gen-list li .msg {
    font-size: 11px;
    line-height: normal;
    color: #999;
    display: block;
}




/********** 4. LEFT PANEL **********/
/***********************************/

.leftpanel {
    width: 240px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
}

.sticky-leftpanel {
    position: fixed;
    height: 100%;
    overflow-y: auto;
}

.logopanel {
    padding: 20px;
    background: #fff;
}

.logopanel a:hover{
    text-decoration:none;
}

.logopanel h1 {
    font-size: 30px;
    font-family: 'LatoBold', 'Lucida Grande', 'Lucida Sans Unicode', Helvetica, sans-serif;
    letter-spacing: 1.5px;
    color: #1d2939;
    line-height: 40px;
    margin: 0;
}

.logopanel h1 span {
    color: #1caf9a;
}

.leftpanelinner {
    padding: 5px 15px;
}

.leftpanel .searchform {
    display: none;
}

.leftpanel .searchform::after {
    content: '';
    display: block;
    clear: both;
}

.leftpanel .searchform input {
    padding: 10px;
    height: auto;
    width: 100%;
    margin: 5px 0 20px 0;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
}

.leftpanel .userlogged {
    margin: 0;
    padding: 0;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid rgba(255,255,255,0.1);
    display: none;
}

.leftpanel .userlogged .media-object {
    width: 40px;
    -moz-border-radius: 100px;
    -webkit-border-radius: 100px;
    border-radius: 100px;
    float: left;
    border: 2px solid #1CAF9A;
    padding: 2px;
    margin-left: 2px;
}

.leftpanel .userlogged .media-body {
    margin-left: 50px;
    color: #ccc;
}

.leftpanel .userlogged .media-body h4 {
    font-size: 15px;
    margin: 0;
}

.leftpanel .userlogged .media-body span {
    font-style: italic;
    font-size: 11px;
    opacity: 0.5;
}



/***** 4.1 NAVIGATION STYLES *****/


.sidebartitle {
    font-size: 11px;
    text-transform: uppercase;
    color: #999;
    margin-bottom: 10px;
}

.nav-bracket {
    margin-bottom: 30px;
}

.nav-bracket > li > a {
    color: #8f939e;
    padding: 7px 10px;
}

.nav-bracket > li > a:hover,
.nav-bracket > li > a:active,
.nav-bracket > li > a:focus {
    background-color: #fff;
    color: #1d2939;
    -moz-box-shadow: 0 3px 0 rgba(0,0,0,0.2);
    -webkit-box-shadow: 0 3px 0 rgba(0,0,0,0.2);
    box-shadow: 0 3px 0 rgba(0,0,0,0.2);
}

.nav-bracket > li.nav-parent > a{/*
    background: transparent url(../images/plus-white.png) no-repeat 92% center;*/
}

.nav-bracket > li.nav-parent > a .iconfont{
    float: right;
}

.nav-bracket > li.nav-parent > a:focus {
    color: #8F939E;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.nav-bracket > li.nav-parent > a:hover,
.nav-bracket > li.nav-parent > a:active {
    color: #1d2939;
    background-color: #fff;/*
    background-image: url(../images/plus.png);*/
}

.nav-bracket > li.nav-parent > input{
    display: none;
}

.nav-bracket > li.nav-parent > input:checked ~ ul{
    display: inline;
}

.nav-bracket > li.nav-parent > input:checked ~ i{
    content: "\e686";
    color: #1D2939;
}

.nav-bracket > li.nav-active > a {
    background-color: #fff;/*
    background-image: url(../images/minus.png);*/
    color: #1d2939;
    -moz-box-shadow: 0 3px 0 rgba(0,0,0,0.2);
    -webkit-box-shadow: 0 3px 0 rgba(0,0,0,0.2);
    box-shadow: 0 3px 0 rgba(0,0,0,0.2);
}

.nav-bracket > li.nav-active > a:hover {/*
    background-image: url(../images/minus.png);*/
    background-repeat: no-repeat;
}

.nav-bracket > li.nav-active > a:focus {
    color: #1d2939;
}

.nav-bracket > li.active > a,
.nav-bracket > li.active > a:hover,
.nav-bracket > li.active > a:focus {
    background-color: #1d2939;
    color: #fff;
}

.nav-bracket > li.active > a {
    -moz-box-shadow: 0 3px 0 rgba(0,0,0,0.2);
    -webkit-box-shadow: 0 3px 0 rgba(0,0,0,0.2);
    box-shadow: 0 3px 0 rgba(0,0,0,0.2);
}/*

.nav-bracket > li.nav-parent.active > a {
    background-image: url(../images/plus.png); 
}

.nav-bracket > li.nav-active.active > a {
    background-image: url(../images/minus.png); 
}

.nav-bracket > li.nav-active.active > a:hover {
    background-image: url(../images/minus.png); 
}*/

.nav-bracket li .fa,
.nav-bracket li .glyphicon {
    font-size: 16px;
    vertical-align: middle;
    margin-right: 10px;
    width: 16px;
    text-align: center;
}

.nav-bracket > li > a > .badge {
    margin-top: 2px;
}

.nav-bracket > li > a > label {
    width: 100%;
}

.nav-bracket .children {
    list-style: none;
    display: none;
    margin: 5px 0;
    padding: 0;
}

.nav-bracket .children > li > a {
    color: #94989d;
    font-size: 13px;
    display: block;
    padding: 5px 0 5px 27px;
    -moz-transition: all 0.2s ease-out 0s;
    -webkit-transition: all 0.2s ease-out 0s;
    transition: all 0.2s ease-out 0s;
}

.nav-bracket .children > li > a > span {
    margin-top: 2px;
}

.nav-bracket .children > li > a:hover,
.nav-bracket .children > li > a:active,
.nav-bracket .children > li > a:focus {
    text-decoration: none;
    color: #1caf9a;
}

.nav-bracket .children > li .fa {
    font-size: 12px;
    opacity: 0.5;
    margin-right: 5px;
    text-align: left;
    width: auto;
    vertical-align: baseline;
}

.nav-bracket .children > li.active > a {
    color: #1caf9a;
}

.nav-bracket .children ul {
    margin-left: 12px;
    border: 0;
}



/***** 4.2 INFO SUMMARY *****/


.infosummary {
    margin: 30px 0;
}

.infosummary ul {
    padding: 0;
    margin: 20px 0 0;
    list-style: none;
    border-top: 1px solid rgba(255,255,255,0.05);
}

.infosummary li {
    padding: 10px 0;
    border-bottom: 1px solid rgba(255,255,255,0.05);
}

.infosummary li:after {
    clear: both;
    display: block;
    content: '';
}

.infosummary .datainfo {
    float: left;
    line-height: normal;
}

.infosummary .datainfo h4 {
    margin: 0;
    font-size: 16px;
    color: #fff;
    opacity: 0.7;
}

.infosummary span {
    font-size: 11px;
    line-height: normal;
    text-transform: uppercase;
    font-size: 10px;
    opacity: 0.4;
}

.infosummary .chart {
    float: right;
    margin-top: 5px;
    opacity: 0.6;
}



/********** 5. MAIN PANEL **********/
/***********************************/

.transparency{
    background-color: transparent;
}

.histogramtitle{
    min-height: 180px;
    width: 100%;
}

.svgbg{
    min-height: 1080px;
}

.histogram{
    min-height: 180px;
}

.histogramlar{
    min-height: 240px;
}

.histogramlar1{
    min-height: 240px;
    width: 90%;
    margin-top: -70px;
}

.histogrambottom{
    min-height: 310px;
}

.histogramm{
    min-height: 420px;
}

.histogramline{
    min-height: 500px;
}

.datamaterialspie{
    min-height: 560px;
    margin-left: -120px;
}

.mapgb{
    min-height: 980px;
    z-index: 0;
}

.gborderlh .histogramfooter{
    height: 50px;
    width: 100%;
    background-color: rgba(9, 18, 32, 1);
    position: absolute;
    bottom: 0;
}

.mainmax {
    width: 1920px;
    min-height: 1060px;
    background-color: rgba(13, 11, 58, 0.8);
    background-size: contain;
}

.mainmax .centertext{
    color: #91BBFF;
    margin-left: 62%;
    margin-top: 400px;
    font-size: 20px;
    position: absolute;
    text-align: center;
    font-weight: bold;
}

.mainmax .centertext strong{
    color: #FFFFFF;
    margin-left: 64%;
    margin-top: 400px;
    font-size: 20px;
    position: absolute;
    text-align: center;
}

.mainmax .bg{
    min-height: 600px;
    background: url(/src/img/bg_center1.png);
    background-size: contain;
    background-repeat: no-repeat;
    position: fixed;
    width: 100%;
    top:14%;
    left: 20%;
}

.mainmax .topheader{
    min-height: 100px;
    background: url(/src/img/header_bg.png);
    background-size: contain;
    background-repeat: no-repeat;
    padding-top: 10px;
}

.mainmax .topheader .logo{
    width: 50px;
    min-height: 50px;
    background: url(/src/img/logo.png);
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: 33%;
    margin-top: 8px;
}

.mainmax .topheader .rightbutton{

    color: #FFFFFF;
    border-color:#5ba6ea;
    position: absolute;
    padding: 5px;
    margin-top: -50px;
    margin-right: 80px;
    border-style: groove ;
    background-color: rgba(20, 62, 119, 0.9);
    cursor:pointer;
    text-decoration-line: none;
}

.mainmax .topheader .rightscreen{

    color: #FFFFFF;
    border-color:#5ba6ea;
    position: absolute;
    padding: 5px;
    margin-top: -50px;
    margin-right: 180px;
    border-style: groove ;
    background-color: rgba(20, 62, 119, 0.9);
    text-decoration-line: none;
}

.mainmax .width {
    float: left;
    flex-direction: row;
    width: 100%;
    display: inline;
    height: 320px;
}

.mainmax .datawheigh {
    width: 80%;
    height: 140px;
    margin-left: 80px;
    margin-right: 80px;
    position: absolute;
    background-color: rgba(122, 191, 255, 0.05);
}

.mainmax .datafheigh2 {
    width: 80%;
    height: 100px;
    margin-left: 80px;
    margin-right: 80px;
    position: absolute;
}

.mainmax .datafheigh3 {
    width: 20%;
    height: 400px;
    margin-left: 80px;
    margin-right: 80px;
    position: absolute;
    background-color: rgba(122, 191, 255, 0.1);
}

.mainmax .datamadeheigh {
    width: 30%;
    height: 400px;
    margin-left: 80px;
    margin-right: 80px;
    position: absolute;
    background-color: rgba(122, 191, 255, 0.1);
    text-align: center;
}

.mainmax .datamadeheigh2 {
    width: 22%;
    height: 150px;
    margin-left: 80px;
    margin-right: 80px;
    position: absolute;
    background-color: rgba(122, 191, 255, 0.1);
}

.mainmax .datamadeheigh2 .mineral{
    color: #FFFFFF;
    width: 170px;
    height: 100px;
    background-color: rgba(15, 26, 61, 0.8);
    position: relative;
    display: inline-block;
    margin: 10px;
    text-align: center;
    margin-left: 5%;
}

.mainmax .datawheigh .mineral{
    color: #FFFFFF;
    width: 124px;
    height: 70px;
    background-color: rgba(15, 26, 61, 0.8);
    position: relative;
    display: inline-block;
    margin: 10px;
    text-align: center;
    margin-left: 2%;
}

.mainmax .datamheigh {
    width: 20%;
    margin-left: 80px;
    margin-right: 80px;
    position: absolute;
    background-color: rgba(122, 191, 255, 0.05);
}

.mainmax .datafiringheigh {
    width: 28%;
    height: 380px;
    margin-left: 80px;
    margin-right: 80px;
    position: absolute;
    background-color: rgba(122, 191, 255, 0.01);
}

.mainmax .datamadeheigh3 {
    width: 28%;
    height: 430px;
    margin-left: 80px;
    margin-right: 80px;
    position: absolute;
    background-color: rgba(122, 191, 255, 0.01);
}

.mainmax .datamaterialsheigh {
    width: 40%;
    height: 480px;
    margin-left: 80px;
    margin-right: 80px;
    position: absolute;
    background-color: rgba(122, 191, 255, 0.01);
}

.mainmax .datamaterialsheigh .mineral{
    color: #FFFFFF;
    border-color:#5ba6ea;
    padding: 10px;
    margin: 10px;
    height: 600px;
}

.mainmax .datampie {
    width: 28%;
    height: 640px;
    margin-left: 80px;
    margin-right: 80px;
    position: absolute;
    background-color: rgba(122, 191, 255, 0.01);
}

.mainmax .datamheigh .mineral{
    color: #FFFFFF;
    width: 200px;
    height: 100px;
    background-color: rgba(15, 26, 61, 0.8);
    position: relative;
    display: inline-block;
    margin: 10px;
    text-align: center;
    left: 20%;
}

.mainmax .datafheigh {
    width: 26%;
    margin-left: 80px;
    margin-right: 80px;
    position: absolute;
    background-color: rgba(122, 191, 255, 0.1);
}

.mainmax .firing{
    color: #FFFFFF;
    width: 100px;
    height: 100px;
    background-color: rgba(15, 26, 61, 0.8);
    position: relative;
    display: inline-block;
    margin: 10px;
    text-align: center;
    left: 2%;
}

.mainmax .texture1{
    width: 140px;
    height: 6px;
    background: linear-gradient(45deg,rgba(145, 187, 255, 0.4),rgba(31, 53, 100, 0.4),rgba(145, 187, 255, 0.4),rgba(31, 53, 100, 0.4)
    ,rgba(145, 187, 255, 0.4),rgba(31, 53, 100, 0.4),rgba(145, 187, 255, 0.4),rgba(31, 53, 100, 0.4),rgba(145, 187, 255, 0.4)
    ,rgba(31, 53, 100, 0.4),rgba(145, 187, 255, 0.4),rgba(31, 53, 100, 0.4));
    margin-left: 30px;
    margin-top: 20px;
    margin-bottom: 10px;
    animation-name: run;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
    box-shadow: 0 0 2px 2px rgba(145, 187, 255, 0.1);
}

.mainmax .texture{
    width: 200px;
    height: 14px;
    background: linear-gradient(45deg,rgba(145, 187, 255, 0.4),rgba(31, 53, 100, 0.4),rgba(145, 187, 255, 0.4),rgba(31, 53, 100, 0.4)
    ,rgba(145, 187, 255, 0.4),rgba(31, 53, 100, 0.4),rgba(145, 187, 255, 0.4),rgba(31, 53, 100, 0.4),rgba(145, 187, 255, 0.4)
    ,rgba(31, 53, 100, 0.4),rgba(145, 187, 255, 0.4),rgba(31, 53, 100, 0.4));
    margin-left: 150px;
    margin-top: 20px;
    margin-bottom: 10px;
    animation-name: run;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
    box-shadow: 0 0 2px 2px rgba(145, 187, 255, 0.1);
}

.mainmax .texturebottom{
    height: 14px;
    background: linear-gradient(45deg,rgba(145, 187, 255, 0.4),rgba(31, 53, 100, 0.4),rgba(145, 187, 255, 0.4),rgba(31, 53, 100, 0.4)
    ,rgba(145, 187, 255, 0.4),rgba(31, 53, 100, 0.4),rgba(145, 187, 255, 0.4),rgba(31, 53, 100, 0.4),rgba(145, 187, 255, 0.4)
    ,rgba(31, 53, 100, 0.4),rgba(145, 187, 255, 0.4),rgba(31, 53, 100, 0.4));
    margin: 20px;
    animation-name: run;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
    box-shadow: 0 0 2px 2px rgba(145, 187, 255, 0.1);
}

@keyframes run {
    0%   {
        background: linear-gradient(45deg,rgba(145, 187, 255, 0.4),rgba(31, 53, 100, 0.4),rgba(145, 187, 255, 0.4),rgba(31, 53, 100, 0.4)
        ,rgba(145, 187, 255, 0.4),rgba(31, 53, 100, 0.4),rgba(145, 187, 255, 0.4),rgba(31, 53, 100, 0.4),rgba(145, 187, 255, 0.4)
        ,rgba(31, 53, 100, 0.4),rgba(145, 187, 255, 0.4),rgba(31, 53, 100, 0.4));}
    50%  {
        background: linear-gradient(45deg,rgba(31, 53, 100, 0.4),rgba(145, 187, 255, 0.4),rgba(31, 53, 100, 0.4)
        ,rgba(145, 187, 255, 0.4),rgba(31, 53, 100, 0.4),rgba(145, 187, 255, 0.4),rgba(31, 53, 100, 0.4),rgba(145, 187, 255, 0.4)
        ,rgba(31, 53, 100, 0.4),rgba(145, 187, 255, 0.4),rgba(31, 53, 100, 0.4),rgba(145, 187, 255, 0.4));}
    100%  {
        background: linear-gradient(45deg,rgba(145, 187, 255, 0.4),rgba(31, 53, 100, 0.4),rgba(145, 187, 255, 0.4),rgba(31, 53, 100, 0.4)
        ,rgba(145, 187, 255, 0.4),rgba(31, 53, 100, 0.4),rgba(145, 187, 255, 0.4),rgba(31, 53, 100, 0.4),rgba(145, 187, 255, 0.4)
        ,rgba(31, 53, 100, 0.4),rgba(145, 187, 255, 0.4),rgba(31, 53, 100, 0.4));}
}

.mainmax .titletop{
    display: flex;
    color: #91BBFF;
    margin-top: 30px;
}

.mainmax .titletop p{
    font-size: 20px;
    margin-left: 10px;
}

.mainmax .titletop i{
    margin-left: 10px;
    font-size: 20px;
}

.mainmax .titlebottom{
    display: flex;
    color: #91BBFF;
    margin-top: -40px;
}

.mainmax .titlebottom p{
    font-size: 20px;
    margin-left: 10px;
}

.mainmax .titlebottom i{
    margin-left: 10px;
    font-size: 20px;
}

.mainmax .title{
    display: flex;
    color: #91BBFF;
}

.mainmax .title p{
    font-size: 20px;
    margin-left: 10px;
}

.mainmax .title i{
    font-size: 40px;
    margin-top: -10px;
}

.mainmax .title .rbgm{
    margin-left: 8px;
    width: 10px;
    height: 40px;
    background: rgba(145, 187, 255, 0.4);
    margin-top: -10px;
}

.mainmax .title .rbg{
    margin-left: 8px;
    width: 60%;
    height: 40px;
    background: linear-gradient(to right,rgba(145, 187, 255, 0.4),rgba(0, 0, 0, 0));
    margin-top: -10px;
}

.mainmax .lt{
    width: 10px;
    height: 10px;
    border-top: 3px solid #c0c0c0;
    border-left: 3px solid #c0c0c0;
    border-color: #69E0F8;
}

.mainmax .rt{
    width: 10px;
    height: 10px;
    border-bottom: 3px solid #c0c0c0;
    border-right: 3px solid #c0c0c0;
    right: 0;
    bottom: 0;
    position: absolute;
    border-color: #69E0F8;
}

.mainmax .heigh {
    width: 20%;
    height: 750px;
    margin-left: 80px;
    margin-right: 80px;
    position: absolute;
}

.mainmax .heigh .w{
    display: flex;
    width: 200%;
    height: 150px;
}

.mainmax .heigh .bordert{
    float: left;
    color: #FFFFFF;
    padding: 10px;
    margin: 10px;
    width: 100%;
    height: 240px;
    background-color: rgba(15, 26, 61, 0);
    position: absolute;
}

.mainmax .heigh .borderh{
    color: #FFFFFF;
    margin: 10px;
    width: 150%;
    height: 294px;
    background-color: rgba(15, 26, 61, 0.8);
    text-align: center;
    margin-top: 30px;
    box-shadow: 20px 20px 10px rgba(3, 14, 51, 0.8);
    position: absolute;
}

@keyframes flash {
    0%   {border-color:#5ba6ea;box-shadow: 20px 20px 10px rgba(3, 14, 51, 0.8);}
    50%  {border-color:#888888;box-shadow: 1px 1px 10px 10px #5ba6ea;}
    100%  {border-color:#5ba6ea;box-shadow: 20px 20px 10px rgba(3, 14, 51, 0.8);}
}

.mainmax .heigh .borderhtop{
    color: #FFFFFF;
    margin: 10px;
    width: 150%;
    height: 294px;
    background-color: rgba(15, 26, 61, 0.8);
    text-align: center;
    margin-top: 500px;
    box-shadow: 20px 20px 10px rgba(3, 14, 51, 0.8);
    position: absolute;
}

.mainmax .heigh .borderhs{
    display: flex;
    float: left;
    color: #FFFFFF;
    border-color:#5ba6ea;
    padding: 10px;
    margin: 10px;
    border-style:groove;
    width: 150%;
    height: 140px;
    background-color: rgba(15, 26, 61, 0.8);
    text-align: center;
}

.mainmax .heigh .border{
    float: left;
    color: #FFFFFF;
    border-color:#5ba6ea;
    padding: 10px;
    margin: 10px;
    border-style:groove;
    width: 100%;
    height: 240px;
    background-color: rgba(15, 26, 61, 0.8);
}

.mainmax .heigh .bordersellw{
    float: left;
    color: #FFFFFF;
    margin: 10px;
    width: 172%;
    height: 610px;
    background-color: rgba(122, 191, 255, 0.1);
    position: absolute;
}

.mainmax .heigh .bordersellww{
    float: left;
    color: #FFFFFF;
    margin: 10px;
    width: 130%;
    height: 294px;
    background-color: rgba(122, 191, 255, 0.1);
    position: absolute;
}

.mainmax .heigh .bordersellwww{
    float: left;
    color: #FFFFFF;
    margin: 10px;
    width: 170%;
    height: 294px;
    background-color: rgba(122, 191, 255, 0.1);
    position: absolute;
}

.mainmax .heigh .borderw{
    float: left;
    color: #FFFFFF;
    margin: 10px;
    width: 150%;
    height: 330px;
    background-color: rgba(122, 191, 255, 0.01);
    position: absolute;
}

.mainmax .heigh .borderwh{
    float: left;
    color: #FFFFFF;
    margin: 10px;
    width: 150%;
    height: 580px;
    background-color: rgba(122, 191, 255, 0.01);
    position: absolute;
}

.mainmax .heigh .borderwh .p{
    font-size: 6px;
}

.mainmax .heigh .borderww{
    float: left;
    color: #FFFFFF;
    border-color:#5ba6ea;
    padding: 10px;
    margin: 10px;
    border-style:groove;
    width: 180%;
    height: 300px;
    background-color: rgba(15, 26, 61, 0.8);
}

.mainmax .heigh .borderl{
    float: left;
    color: #FFFFFF;
    border-color:#5ba6ea;
    padding: 10px;
    margin: 10px;
    width: 300%;
    height: 330px;
    background-color: rgba(122, 191, 255, 0.01);
    position: absolute;
}

.mainmax .heigh .borderll{
    float: left;
    color: #FFFFFF;
    padding: 10px;
    margin: 10px;
    width: 270%;
    height: 320px;
    background-color: rgba(15, 26, 61, 0.01);
    position: absolute;
}

.mainmax .heigh .borderselll{
    float: left;
    color: #FFFFFF;
    margin: 10px;
    width: 270%;
    height: 300px;
    background-color: rgba(122, 191, 255, 0.1);
    position: absolute;
}

.mainmax .colorbg .mineral .lt{
    width: 10px;
    height: 10px;
    border-top: 3px solid #c0c0c0;
    border-left: 3px solid #c0c0c0;
}

.mainmax .colorbg .mineral .rt{
    width: 10px;
    height: 10px;
    border-bottom: 3px solid #c0c0c0;
    border-right: 3px solid #c0c0c0;
    right: 0;
    bottom: 0;
    position: absolute;
}

.mainmax .colorbg .stockbg{
    color: #FFFFFF;
    margin: 10px;
    width: 200px;
    height: 110px;
    background-color: rgba(15, 26, 61, 0.8);
    position: relative;
    display: inline-block;
}

.mainmax .colorbg .stockbg i{
    margin: 24px;
    position: absolute;
    font-size: 32px;
}

.mainmax .colorbg .stockbg p{
    font-size: 16px;
    margin-top: 20px;
    margin-left: 80px;
}

.mainmax .colorbg .stockbgl{
    color: #FFFFFF;
    border-color:#5ba6ea;
    padding: 10px;
    margin: 10px;
    border-style: dashed;
    width: 200px;
    height: 110px;
    background-color: rgba(15, 26, 61, 0.8);
}

.mainmax .colorbg .stockbg i{
    margin: 24px;
    position: absolute;
    font-size: 32px;
}

.mainmax .colorbg .stockbg p{
    font-size: 16px;
    margin-top: 20px;
    margin-left: 80px;
}

.mainmax .colorbg .stockbgh{
    color: #FFFFFF;
    margin: 10px;
    width: 140px;
    position: absolute;
    padding-right: 20px;
}

.mainmax .colorbg .stockbgh p{
    font-size: 18px;
    color: rgba(108, 132, 194, 0.8);
    font-weight: bold;
}

.mainmax .colorbg .stockbgh p strong{
    font-size: 18px;
    color: rgb(255, 255, 255);
    font-weight: bold;
}

.mainmax .colorbg .stockbghs{
    color: #FFFFFF;
    border-color:#5ba6ea;
    padding: 10px;
    margin: 10px;
    border-style: dashed;
    width: 30%;
    height: 100px;
    background-color: rgba(15, 26, 61, 0.8);
}

.mainmax .colorbg .stockbghl{
    color: #FFFFFF;
    margin: 10px;
    width: 100%;
    height: 200px;
    background-color: rgba(15, 26, 61, 0.8);
    margin-top: 200px;
    position: absolute;
    text-align: center;
}

.mainmax .colorbg .stockbghl p{
    color:#5ba6ea;
}

.mainmax .colorbg .stockbghl i{
    font-size: 40px;
    color: rgb(255, 255, 255);
    animation-name: rotate;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
    position: absolute;
    margin-left: 83%;

}

@keyframes rotate {
    0%   {transform:rotate(0deg);}
    25%  {transform:rotate(20deg);}
    50%  {transform:rotate(40deg);}
    75%  {transform:rotate(60deg);}
    100%  {transform:rotate(80deg);}
}

.mainmax .colorbg .stockbghll{
    color: #FFFFFF;
    margin: 10px;
    width: 180%;
    height: 420px;
    background-color: rgba(15, 26, 61, 0.3);
    margin-left: -150px;
    position: absolute;
}

.mainmax .borderlh{
    color: #FFFFFF;
    border-color:#5ba6ea;
    position: absolute;
    padding: 10px;
    margin: 10px;
    border-style:outset;
    width: 650px;
    height: 200px;
    background-color: rgba(20, 62, 119, 0.8);
}

.mainmax .gborderlh{
    position: absolute;
    width: 1920px;
    height: 980px;
    background-color: rgba(13, 11, 58, 0.8);
}

.mainmax .gborder{/*
    background: url(/src/img/bg_center.png);
    background-repeat: repeat;*/
    position: absolute;
    width: 100%;
    height: 980px;
    background-color: rgba(13, 11, 58, 0.8);
}

.mainmax .colorbg{
    position: absolute;
    width: 100%;
    height: 980px;
    background-color: rgba(11, 11, 58, 0.8);
}

.mainmax .borderllh{
    color: #FFFFFF;
    position: absolute;
    padding: 10px;
    margin: 10px;
    width: 650px;
    height: 100px;
    background-color: rgba(20, 62, 119, 0.4);
}

.mainmax .borderllh h4{
    color: #843534;
    position: absolute;
    top:40%;
    margin-top: 16px;
    margin-left: 60px;
    font-size: 20px;
}

.mainmax .borderllh h5{
    color: #5ba6ea;
    position: absolute;
    margin-top: 20px;
    margin-left: 60px;
    font-size: 16px;
}

.mainmax .width .border{
    color: #FFFFFF;
    border-color:#5ba6ea;
    position: absolute;
    padding: 10px;
    margin: 10px;
    border-style:outset;
    width: 450px;
    height: 300px;
    background-color: rgba(20, 62, 119, 0.8);
}

.mainmax .width .borderh{
    color: #FFFFFF;
    border-color:#5ba6ea;
    position: absolute;
    padding: 10px;
    margin: 10px;
    border-style:outset;
    width: 450px;
    height: 300px;
    background-color: rgba(20, 62, 119, 0.8);
}

.mainmax .width .borderw{
    color: #FFFFFF;
    border-color:#5ba6ea;
    position: absolute;
    padding: 10px;
    margin: 10px;
    border-style:outset;
    width: 700px;
    height: 300px;
    background-color: rgba(20, 62, 119, 0.8);
}

.mainmax .width .borderl{
    color: #FFFFFF;
    border-color:#5ba6ea;
    position: absolute;
    padding: 10px;
    margin: 10px;
    border-style:outset;
    width: 340px;
    height: 300px;
    background-color: rgba(20, 62, 119, 0.8);
}

.mainmax .width .borderll{
    color: #FFFFFF;
    border-color:#5ba6ea;
    position: absolute;
    padding: 10px;
    margin: 10px;
    border-style:outset;
    width: 300px;
    height: 300px;
    background-color: rgba(20, 62, 119, 0.8);
}

.top0{
    top:0;
}

.top10{
    top:4%;
}

.top12{
    top:7%;
}

.top15{
    top:10%;
}

.top{
    top:24%;
}

.top20{
    top:32%;
}

.top30{
    top:36%;
}

.top40{
    top:42%;
}

.top50{
    top:56%;
}

.top51{
    top:61%;
}
.top52{
    top:62%;
}
.top53{
    top:63%;
}

.top60{
    top:69%;
}

.top70{
    top:72%;
}

.top75{
    top:80%;
}

.top80{
    top:85%;
}

.top85{
    top:90%;
}

.center{
    left:32%;
}

.right{
    right:8%;
}

.right5{
    right:-36%;
}

.right0{
    right:0;
}

.left-10{
    left:-35%;
}

.left5{
    left:7%;
}

.left10{
    left:10%;
}

.left15{
    left:15%;
}

.left20{
    left:25%;
}

.left30{
    left:32%;
}

.left35{
    left:36%;
}

.left40{
    left:55%;
}

.left50{
    left:60%;
}

.left70{
    left:64%;
}

.left80{
    left:110%;
}

.mainmax .topheader h1{
    color: #6de3ff;
    position: absolute;
    text-align: center;
    padding: 1%;
    text-shadow:3px 4px 5px #2096ff;
    top:-2%;
    left:36%
}

.mainmax .topheader h3{
    color: #fff;
    position: absolute;
    top:0;
    margin-top: 24px;
    margin-left: 60px;
    font-size: 20px;
    text-shadow:3px 4px 5px #fff;
    font-family: Arial,Helvetica,sans-serif;
}

.mainmax .topheader .iconfont{
    color: #fff;
    position: absolute;
    top:0;
    margin-top: 24px;
    margin-left: 30%;
    font-size: 32px;
    text-shadow:3px 4px 5px #fff;
    font-family: Arial,Helvetica,sans-serif;
}

.mainmax .topheader .iconfont:active{
    color: #aaa;
    position: absolute;
    top:0;
    margin-top: 24px;
    margin-left: 30%;
    font-size: 32px;
    text-shadow:1px 2px 3px #fff;
    font-family: Arial,Helvetica,sans-serif;
}

.mainmax .topheader .datamenu i{
    cursor:pointer;
}

.mainmax .topheader .datamenu > input{/*
    display: none;*/
}

.mainmax .topheader .datamenu:active ~ .leftpanel{
    display: none;
}

.mainmax .topheader .datamenu > input:checked ~ .leftpanel{
    display: none;
}

.datamenushow{
    z-index: 9;
    width: 14%;
    height: 1080px;
    background-color: rgb(9, 18, 32, 0.9);
    position: absolute;
    top:0;
    transition: width 2s ease 0s;
    border-width:10px;
    border-color: rgb(78, 123, 211, 0.4);
    border-top-style:none;
    border-right-style:ridge;
    border-bottom-style:none;
    border-left-style:none;

}

.datamenushow .datamenubutton{
    z-index: 9;
    width: 100%;
    height: 90px;
    color: #bbbbbb;
    font-size: 20px;
    text-align: center;
    border-bottom-style:ridge;
    border-top-style:none;
    border-right-style:none;
    border-left-style:none;
    border-color: rgb(15, 57, 141, 0.6);
    padding: 34px;

}

.datamenushow .datamenubutton:hover{
    z-index: 9;
    width: 100%;
    height: 90px;
    color: #bbbbbb;
    font-size: 20px;
    text-align: center;
    border-bottom-style:ridge;
    border-top-style:none;
    border-right-style:none;
    border-left-style:none;
    border-color: rgb(15, 57, 141, 0.6);
    padding: 34px;
    background-color: #2A6496;
}

.datamenushow .datamenubutton a{
    text-decoration: none;
}

.datamenushow .datamenubutton a:hover{
    text-decoration: none;
    color: #FFFFFF;
}

.mainpanel {
    margin-left: 240px;
    background: #e4e7ea;
    min-height: 1000px;
}

.pageheader {
    padding: 15px;
    border-bottom: 1px solid #d3d7db;
    border-top: 1px solid #eee;
    background: #f7f7f7;
    position: relative;
}

.pageheader h2 {
    font-size: 28px;
    color: #1D2939;
    letter-spacing: -0.5px;
    margin: 0;
}

.pageheader .glyphicon,
.pageheader .fa {
    font-size: 24px;
    margin-right: 5px;
    padding: 6px 7px;
    border: 2px solid #1D2939;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
}

.pageheader .fa.fa-pencil {
    padding: 6px 9px;
}

.pageheader .fa.fa-hand-o-up {
    padding: 6px 9px 6px 7px;
}

.pageheader .fa-file-o {
    padding: 6px 10px;
}

.pageheader .fa-user {
    padding: 6px 10px;
}

.pageheader .fa-file-text {
    padding: 6px 10px;
}

.pageheader .fa-map-marker {
    padding: 6px 12px;
}

.pageheader .fa-dollar {
    padding: 6px 12px;
}

.pageheader .fa-clock-o {
    padding: 6px 8px;
}

.pageheader h2 span {
    font-size: 13px;
    text-transform: none;
    color: #999;
    font-style: italic;
    vertical-align: middle;
    letter-spacing: 0;
}

.pageheader h2 span::before {
    content: '/';
    margin: 0 10px 0 5px;
    color: #ccc;
}

.pageheader .breadcrumb-wrapper {
    position: absolute;
    top: 23px;
    right: 25px;
}

.pageheader .breadcrumb-wrapper .label {
    color: #999;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: normal;
    display: inline-block;
}

.pageheader .breadcrumb {
    background: none;
    display: inline-block;
    padding: 0;
}

.pageheader .breadcrumb li {
    font-size: 12px;
}

.pageheader .breadcrumb li.active {
    color: #333;
}

.contentpanel {
    padding: 20px;
    position: relative;
}

.contentpanel::after {
    clear: both;
    display: block;
    content: '';
}

form .form-group:last-child {
    margin-bottom: 0;
}

.row-pad-5 {
    margin-left: -5px;
    margin-right: -5px;
}

.row-pad-5 [class*="col-lg"],
.row-pad-5 [class*="col-md"],
.row-pad-5 [class*="col-sm"] {
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 10px;
}

.subtitle {
    font-size: 13px;
    text-transform: uppercase;
    color: #333;
    font-family: 'LatoBold';
    margin-bottom: 15px;
    margin-top: 0;
}

.subtitle-lined {
    border-bottom: 1px dotted #ddd;
    padding-bottom: 5px;
}

p {
    margin-bottom: 10px;
}



/***** 5.1. DASHBOARD ******/


.panel-stat {
    background: none;
}

.panel-stat .panel-heading {
    padding: 15px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
}

.panel-stat .stat {
    color: #fff;
    max-width: 250px;
}

.panel-stat .stat img {
    text-align: center;
}

.panel-stat i {
    font-size: 30px;
    border: 1px solid #fff;
    padding: 15px;
    border-radius: 50px;
    opacity: 0.5;
}

.panel-stat .stat-label {
    text-transform: uppercase;
    font-size: 11px;
    opacity: 0.75;
    display: block;
    line-height: normal;
    margin-bottom: 2px;
}

.panel-stat h1 {
    margin: 0;
    line-height: 36px;
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 38px;
}

.panel-stat h4 {
    margin: 0;
    font-family: 'Helvetica Neue', sans-serif;
}

.sublabel {
    display: block;
    font-size: 12px;
    margin-bottom: 2px;
}

.ex-donut-chart {
    text-align: center;
    height: 298px;
}

.ex-line-chart {
    height: 248px;
}

.ex-donut-chart > svg,
.ex-line-chart > svg {
    width: 100% !important;
}



/********** 6. FORM STYLES **********/
/************************************/



.ckbox,
.rdio {
    position: relative;
}

.ckbox input[type="checkbox"],
.rdio input[type="radio"] {
    opacity: 0;
}

.ckbox label,
.rdio label {
    padding-left: 10px;
    cursor: pointer;
    margin-bottom: 7px !important;
}

.ckbox label:before {
    width: 18px;
    height: 18px;
    position: absolute;
    top: 1px;
    left: 0;
    content: '';
    display: inline-block;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    border: 1px solid #bbb;
    background: #fff;
}

.ckbox input[type="checkbox"]:disabled + label  {
    color: #999;
}

.ckbox input[type="checkbox"]:disabled + label:before  {
    background-color: #eee;
}

.ckbox input[type="checkbox"]:checked + label::after  {
    font-family: 'FontAwesome';
    content: "\F00C";
    position: absolute;
    top: 0;
    left: 3.5px;
    display: inline-block;
    font-size: 11px;
    width: 16px;
    height: 16px;
    color: #fff;
}

.ckbox-default input[type="checkbox"]:checked + label:before {
    border-color: #999;
}

.ckbox-default input[type="checkbox"]:checked + label::after {
    color: #333;
}

.ckbox-primary input[type="checkbox"]:checked + label:before {
    border-color: #357EBD;
    background-color: #428BCA;
}

.ckbox-warning input[type="checkbox"]:checked + label:before {
    border-color: #EEA236;
    background-color: #F0AD4E;
}

.ckbox-success input[type="checkbox"]:checked + label:before {
    border-color: #1CAF9A;
    background-color: #1CAF9A;
}

.ckbox-danger input[type="checkbox"]:checked + label:before {
    border-color: #D43F3A;
    background-color: #D9534F;
}

.rdio label:before {
    width: 18px;
    height: 18px;
    position: absolute;
    top: 1px;
    left: 0;
    content: '';
    display: inline-block;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    border: 1px solid #bbb;
    background: #fff;
}

.rdio input[type="radio"]:disabled + label  {
    color: #999;
}

.rdio input[type="radio"]:disabled + label:before  {
    background-color: #eee;
}

.rdio input[type="radio"]:checked + label::after  {
    content: '';
    position: absolute;
    top: 5px;
    left: 4px;
    display: inline-block;
    font-size: 11px;
    width: 10px;
    height: 10px;
    background-color: #444;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
}

.rdio-default input[type="radio"]:checked + label:before {
    border-color: #999;
}

.rdio-primary input[type="radio"]:checked + label:before {
    border-color: #428BCA;
}

.rdio-primary input[type="radio"]:checked + label::after {
    background-color: #428BCA;
}

.rdio-warning input[type="radio"]:checked + label:before {
    border-color: #F0AD4E;
}

.rdio-warning input[type="radio"]:checked + label::after {
    background-color: #F0AD4E;
}

.rdio-success input[type="radio"]:checked + label:before {
    border-color: #1CAF9A;
}

.rdio-success input[type="radio"]:checked + label::after {
    background-color: #1CAF9A;
}

.rdio-danger input[type="radio"]:checked + label:before {
    border-color: #D9534F;
}

.rdio-danger input[type="radio"]:checked + label::after {
    background-color: #D9534F;
}


pre {
    font-size: 12px;
    color: #C7254E;
    border: 1px solid #ddd;
    padding: 8px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    background: #fff;
}

label.error {
    color: #B94A48;
    margin-top: 2px;
}

label.error:empty {
    display: none !important;    
}


.asterisk {
    color: #D9534F;
}

div.error {
    margin-bottom: 20px;
    display: none;
    position: relative;
}

div.error label.error {
    display: block;
    padding-left: 25px;
    position: relative;
}

div.error label.error:before {
    font-family: 'Glyphicons Halflings';
    content: '\e101';
    position: absolute;
    top: -2px;
    left: 0;
    font-size: 16px;
    color: #D9534F;
    display: inline-block;
}

.bootstrap-timepicker .dropdown-menu {
    top: 40px;
}

/* CKEDITOR */
.ck-editable {
    padding: 10px;
    border: 1px solid #fcfcfc;
    margin-bottom: 20px;
}

.ck-editable:hover {
    border: 1px solid #ccc;
}

.ck-editable h4 {
    color: #333;
}

/* X-EDITABLE */

.editable-input .form-control {
    margin: 0;
}

.editable-input select.form-control {
    padding: 8px 10px
}

.editable-list-group {
    padding: 0;
}

.editableform .form-group {
    margin: 0;
}

.editable-list-item {
    border-bottom: 1px dotted #ddd;
    padding: 12px 15px;
}

.editable-list-group .editable-list-item:last-child {
    border-bottom: 0;
}

.editable-unsaved {
    font-weight: normal;
}



/***** 6.1 FORM WIZARD STYLES *****/


.basic-wizard .nav li a {
    background: #e4e7ea;
    border-bottom: 1px solid #ccc;
    color: #4A535E;
    border-right: 1px solid #ccc;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    opacity: 0.75;
}

.basic-wizard .nav li:last-child a {
    border-right: 0;
}

.basic-wizard .nav li a:hover {
    background-color: #f7f7f7;
    opacity: 1;
}

.basic-wizard .nav li.active a,
.basic-wizard .nav li.active a:focus,
.basic-wizard .nav li.active a:active {
    background: #fcfcfc;
    border-bottom-color: #fcfcfc;
    color: #333;
    opacity: 1;
}

.basic-wizard ul li a span {
    font-family: 'LatoBold', sans-serif;
}

.basic-wizard .pager {
    margin: 0;
    border-top: 1px solid #ddd;
    background: #f7f7f7;
    padding: 10px;
}

.basic-wizard .pager li a {
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    border-color: #357EBD;
    background-color: #428BCA;
    color: #fff;
}

.basic-wizard .pager li a:hover {
    background-color: #3276B1;
    border-color: #285E8E;
}

.basic-wizard .pager li.disabled a {
    background: #eee;
    color: #999;
    border-color: #ccc;
}

.basic-wizard .progress {
    height: 12px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
}

.basic-wizard .nav-disabled-click li a:hover,
.basic-wizard .nav-disabled-click li a:active,
.basic-wizard .nav-disabled-click li a:focus {
    background-color: #E4E7EA;
    opacity: 0.75;
    color: #4A535E;
    cursor: default;
}

.basic-wizard .nav-disabled-click li.active a:hover,
.basic-wizard .nav-disabled-click li.active a:active,
.basic-wizard .nav-disabled-click li.active a:focus {
    background: #fcfcfc;
    color: #333;
    opacity: 1;
}

.basic-wizard .tab-content {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
}

.tab-pane::after {
    clear: both;
    display: block;
    content: '';
}




/********** 7. UI ELEMENTS **********/
/*********************************/


.btn-demo::after {
    clear: both;
    display: block;
    content: '';
}

.btn-demo .btn {
    float: left;
    margin: 0 10px 10px 0;
}

.fonticon-list {
    list-style: none;
    margin: 20px 0;
    padding: 0;
}

.fontawesome-list {
    list-style: none;
    padding: 0;
}

.fonticon-list li,
.fontawesome-list li {
    display: block;
    margin-bottom: 5px;
}

.fonticon-list li span,
.fontawesome-list li span {
    color: #333;
    vertical-align: middle;
    margin-right: 8px;
    min-width: 20px;
    display: inline-block;
    width: 20px;
}

.tooltip-example .btn,
.popover-example .btn {
    margin: 0 5px 5px 0;
}

.tooltipflot {
    background-color: rgba(0,0,0,0.75);
    font-size: 11px;
    color: #fff;
    padding: 3px 10px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
}

.jqstooltip{ 
    min-height: 25px;
    border: 0;
    min-width: 30px;
    height: auto !important;
    width: auto !important;
}

.tinystat {
    display: inline-block;
}

.tinystat .chart,
.tinystat .datainfo {
    display: inline-block;
    vertical-align: middle;
}

.tinystat .chart {
    margin-right: 5px;
}

.tinystat .text-muted {
    text-transform: uppercase;
    font-size: 10px;
}

.tinystat h4 {
    margin: 0;
    color: #333;
    font-family: 'LatoBold';
    line-height: normal;
    font-size: 16px;
}

.panel-morris .body-chart {
    height: 300px;
}

.panel-morris .body-chart > svg {
    width: 100% !important;
}

.widget-quick-status-post .nav-tabs > li > a {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
}

.widget-quick-status-post .panel-body {
    padding: 0;
}

.widget-messaging .panel-btns .panel-edit {
    padding: 15px 17px;
    opacity: 0.5;
    font-size: 24px;
    margin-top: -18px;
    margin-right: -20px;
    position: relative;
}

.widget-messaging .panel-btns .panel-edit:hover {
    opacity: 1;
}

.widget-messaging .panel-body {
    padding: 0;
}

.widget-messaging ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.widget-messaging ul li {
    padding: 15px;
    border-top: 1px solid #eee;
    background-color: #fff;
}

.widget-messaging ul li:hover {
    background-color: #fcfcfc;
}

.widget-messaging ul li:first-child {
    border-top: 0;
}

.widget-messaging .sender {
    font-size: 13px;
    color: #333;
    margin: 0;
    font-family: 'LatoBold';
    line-height: normal;
}

.widget-messaging ul li small {
    line-height: normal;
}

.widget-today .panel-heading,
.widget-time .panel-heading {
    height: 115px;
}

.widget-today .glyphicon,
.widget-today .fa {
    font-size: 72px;
    color: #fff;
    opacity: 1;
}

.widget-time .glyphicon,
.widget-time .fa {
    font-size: 60px;
    color: #fff;
    opacity: 1;
}
.widget-today .today,
.widget-time .today {
    line-height: normal;
    margin: 0;
    color: #333;
    font-family: 'LatoBold';
    font-size: 20px;
}

.widget-photoday .panel-body {
    padding: 0;
}

.widget-photoday .photoday img {
    width: 100%;
    display: block;
    -moz-border-radius: 3px 3px 0 0;
    -webkit-border-radius: 3px 3px 0 0;
    border-radius: 3px 3px 0 0;
}

.widget-photoday .photo-details {
    padding: 15px;
}

.widget-photoday .photo-title {
    color: #333;
    font-size: 16px;
    margin: 0;
    font-family: 'LatoBold';
    line-height: normal;
}

.widget-photoday .photo-details small {
    display: block;
}

.widget-photoday .photo-meta {
    list-style: none;
    padding: 0;
    margin: 0;
    border-top: 1px solid #e3e3e3;
    background-color: #f7f7f7;
}

.widget-photoday .photo-meta li {
    display: table-cell;
    width: 1%;
    text-align: center;
    border-left: 1px solid #e3e3e3;
}

.widget-photoday .photo-meta li:first-child {
    border-left: 0;
}

.widget-photoday .photo-meta li a,
.widget-photoday .photo-meta li span {
    padding: 15px 10px;
    display: block;
    color: #999;
    font-size: 12px;
}

.widget-photoday .photo-meta li a:hover {
    color: #666;
    text-decoration: none;
}

.widget-bloglist .media {
    border-bottom: 1px solid #eee;
    padding: 0;
}

.widget-bloglist .media .thumbnail {
    margin-bottom: 15px;
}

.widget-weather {
    overflow: hidden;
}

.widget-weather .panel-body {
    padding: 0;
    background-color: #1D2939;
}

.widget-weather .row {
    margin: 0;
}

.widget-weather .temp,
.widget-weather .weather {
    padding: 0;
}

.widget-weather .temp {
    background-color: #1CAF9A;
    color: #fff;
    padding: 10px 0;
}

.widget-weather .temp h1 {
    font-size: 60px;
    font-family: 'LatoLight';
}

.widget-weather .temp h1 span {
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-left: -10px;
}

.widget-weather .temp h5 {
    text-transform: uppercase;
}

.widget-weather .weather-icon {
    color: #666;
    font-size: 60px;
    background-color: #fff;
    display: block;
    padding: 20px 0;
}

.widget-weather .weather > div {
    width: 50%;
    text-align: center;
    color: #fff;
    font-size: 14px;
    padding-top: 10px;
}

.widget-profile .panel-heading {
    position: relative;
}

.widget-profile .cover {
    position: absolute;
    top: 0;
    left: 0;
    height: 105px;
    overflow: hidden;
    -moz-border-radius: 3px 3px 0 0;
    -webkit-border-radius: 3px 3px 0 0;
    border-radius: 3px 3px 0 0;
}

.widget-profile .cover img {
    width: 100%;
    display: block;
}

.widget-profile .panel-body {
    position: relative;
    padding: 0;
}

.widget-profile-img {
    position: relative;
    margin-top: 30px;
    margin-left: 15px;
}

.widget-profile-title {
    background: #428BCA;
    margin-top: -77px;
    padding: 15px 0 15px 120px;
    color: #fff;
}

.widget-profile-title h4 {
    font-size: 18px;
    margin: 0;
    line-height: normal;
}

.widget-profile-title small {
    line-height: normal;
}

.widget-profile blockquote {
    font-size: 18px;
    border-left: 0;
    margin: 0;
    color: #555;
    padding: 15px;
}

.widget-profile-title .fa {
    margin-right: 7px;
}

.widget-profile .row {
    margin: 0;
}

.widget-profile .row > div {
    padding: 0;
}

.widget-profile .row > div > span {
    display: block;
    border-right: 1px solid rgba(255,255,255,0.15);
    font-size: 14px;
    padding: 15px 10px;
    text-transform: uppercase;
    background: #1D2939;
    color: #fff;
}

.widget-profile .row > div:first-child span {
    -moz-border-radius: 0 0 0 3px;
    -webkit-border-radius: 0 0 0 3px;
    border-radius: 0 0 0 3px;
}

.widget-profile .row > div:last-child span {
    border-right: 0;
    -moz-border-radius: 0 0 3px 0;
    -webkit-border-radius: 0 0 3px 0;
    border-radius: 0 0 3px 0;
}

.widget-todo .panel-btns i {
    font-size: 12px;
}

.widget-todo .todo-list {
    padding: 0;
    margin: 0;
    list-style: none;
}

.widget-todo .panel-body {
    padding: 0;
}

.widget-todo .todo-list li {
    border-bottom: 1px solid #eee;
    padding: 15px 10px 8px 20px;
}

.widget-todo .todo-list li input:checked + label {
    text-decoration: line-through;
}

.widget-todo .todo-list .todo-form {
    padding-bottom: 15px;   
}

.widget-todo .todo-list li .btn {
    margin: 0;
    padding: 6px 10px;
}

.widget-slider .carousel-control.right,
.widget-slider .carousel-control.left {
    background: none;
}

.widget-slider .carousel-control {
    width: auto;
    text-shadow: none;
    color: #fff;
    bottom: auto;
    top: -55px;
    padding: 0;
    line-height: normal;
}

.widget-slider .carousel-control.left {
    left: auto;
    right: 25px;
}

.widget-slider .carousel-control span {
    line-height: normal;
    font-size: 18px;
}

.widget-slider .media {
    padding-bottom: 0;
}

.widget-slider .carousel-indicators {
    top: -53px;
    right: 0;
    bottom: auto;
    left: auto;
    width: auto;
}




/********** 8. EMAIL **********/
/******************************/



.btn-compose-email {
    padding: 10px 0;
    margin-bottom: 20px;
}

.nav-email > li > a > .badge {
    margin-top: 2px;
}

.nav-email > li > a {
    color: #444;   
}

.nav-email > li > a > i {
    width: 16px;
    text-align: center;
    margin-right: 10px;
}

.nav-email > li > a:hover {
    background-color: #d8dcdf;
}

.table-email {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-bottom: 1px solid #eee;
    margin: 0;
    background-color: #f7f7f7;
}

.table-email tr.unread td {
    background-color: #fff;
}

.table-email tbody > tr > td {
    vertical-align: middle;
}

.table-email tr:hover {
    background-color: #eee;
    cursor: pointer;
}

.table-email tr.selected td {
    background-color: #eee;
}

.table-email .ckbox {
    width: 18px;
    height: 19px;
}

.table-email .ckbox input[type="checkbox"]:checked + label:after {
    top: 2px;
}

.table-email .star {
    color: #ccc;
}

.table-email .star-checked {
    color: #F0AD4E;
}

.table-email td:first-child {
    width: 30px;
}

.table-email td:nth-child(2) {
    width: 30px;
}

.table-email .media {
    margin: 0;
    padding: 0;
}

.table-email .media-object {
    width: 35px;
}

.table-email .media h4 {
    margin: 0;
    font-size: 14px;
    line-height: normal;
    font-family: 'LatoBold', sans-serif;
}

.table-email .media-meta {
    font-size: 11px;
    color: #999;
}

.table-email .email-summary {
    margin: 2px 0 0 0;
}

.table-email .email-summary strong {
    color: #333;
}

.read-panel {
    margin-top: 10px;
    border-top: 1px solid #eee;
    padding-top: 20px;
}

.read-panel .media-object {
    width: 35px;
}

.read-panel .media h4 {
    margin: 2px 0 0 0;
    font-size: 14px;
    line-height: normal;
}

.read-panel .media-meta {
    font-size: 12px;
}

.email-subject {
    color: #333;
}




/********** 9. TABLES **********/
/*******************************/



.dataTables_length .chosen-container {
    min-width: 75px;
}

.dataTables_length .chosen-container-single .chosen-single {
    padding-right: 0;
}

.table-action {
    text-align: center;
}

.table-action-hide a {
    opacity: 0;
}

.table-action a,
.table-action-hide a {
    display: inline-block;
    margin-right: 5px;
    color: #666;
}

.table-action a:hover,
.table-action-hide a:hover {
    color: #333;
}

.table-action a:last-child,
.table-action-hide a:last-child {
    margin-right: 0;
}

.table-primary thead tr th {
    background-color: #428BCA;
}

.table-success thead tr th {
    background-color: #1CAF9A;
}

.table-warning thead tr th {
    background-color: #F0AD4E;
}

.table-danger thead tr th {
    background-color: #D9534F;
}

.table-info thead tr th {
    background-color: #5BC0DE;
}

.table-dark thead tr th {
    background-color: #1D2939;
}

.table.table-primary thead tr th,
.table.table-success thead tr th,
.table.table-danger thead tr th,
.table.table-warning thead tr th,
.table.table-info thead tr th,
.table.table-dark thead tr th {
    color: #fff;
    border-bottom: 0;
}

.table-bordered.table-primary thead tr th,
.table-bordered.table-success thead tr th,
.table-bordered.table-warning thead tr th,
.table-bordered.table-danger thead tr th,
.table-bordered.table-info thead tr th,
.table-bordered.table-dark thead tr th {
    border-color: rgba(255,255,255,0.2);
}



/********** 10. PAGES **********/
/*******************************/


/***** 10.1 CALENDAR *****/

.external-event {
    background: #428BCA;
    margin-bottom: 5px;
    padding: 6px 10px;
    color: #fff;
    font-size: 13px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
}


/***** 10.2 MEDIA MANAGER *****/

.filemanager-options {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
    background: #fcfcfc;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -moz-box-shadow: 0 3px 0 rgba(12,12,12,0.03);
    -webkit-box-shadow: 0 3px 0 rgba(12,12,12,0.03);
    box-shadow: 0 3px 0 rgba(12,12,12,0.03);
}

.filemanager-options li {
    display: inline-block;
    padding: 12px 15px;
    border-right: 1px solid #eee;
    font-size: 12px;
    color: #666;
}

.filemanager-options li a {
    color: #666;
}

.filemanager-options li a.disabled {
    opacity: 0.5;
    cursor: default;
}

.filemanager-options li a.disabled:hover {
    text-decoration: none;
}

.filemanager-options li i {
    font-size: 14px;
    margin-right: 5px;
    color: #444;
}

.filemanager-options .ckbox label {
    margin: 0 !important;
}

.filemanager-options .filter-type {
    float: right;
    border-right: 0;
    border-left: 1px solid #eee;
}

.filemanager-options .filter-type a {
    display: inline-block;
    margin-right: 10px;
}

.filemanager-options .filter-type a:last-child {
    margin-right: 0;
}

.filemanager-options .filter-type a:first-child {
    margin-left: 10px;
}

.filemanager-options .filter-type a.active {
    text-decoration: underline;
    color: #2A6496;
}

.filemanager .thmb {
    border: 1px solid #fcfcfc;
    background: #fcfcfc;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    padding: 10px;
    margin-bottom: 20px;
    position: relative;
    -moz-box-shadow: 0 3px 0 rgba(12,12,12,0.03);
    -webkit-box-shadow: 0 3px 0 rgba(12,12,12,0.03);
    box-shadow: 0 3px 0 rgba(12,12,12,0.03);
}

.filemanager .thmb.checked {
    border-color: #ccc;
}

.filemanager .thmb::after {
    clear: both;
    display: block;
    content: '';
}

.filemanager .ckbox {
    position: absolute;
    top: 15px;
    left: 15px;
    display: none;
}

.filemanager .fm-group {
    position: absolute;
    top: 15px;
    right: 15px;
    display: none;
}

.filemanager .fm-toggle {
    padding: 1px 4px;
    line-height: normal;
    background: #fff;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
}

.filemanager .fm-menu {
    min-width: 120px;
}

.filemanager .fm-menu a {
    font-size: 12px;
    color: #333;
}

.filemanager .fm-menu i {
    margin-right: 7px;
    color: #999;
    width: 16px;
    font-size: 13px;
}

.filemanager .thmb-prev {
    background: #eee;
    overflow: hidden;
}

.filemanager .thmb-prev a {
    display: block;
}

.filemanager .fm-title {
    margin-bottom: 0;
    font-size: 13px;
}

.filemanager .fm-title a {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

.fm-sidebar {
    padding-left: 10px;
}

.fm-sidebar .subtitle a {
    color: #999;
    font-size: 11px;
}

.fm-sidebar .subtitle a:hover {
    text-decoration: none;
    color: #333;
}

.folder-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.folder-list li {
    display: block;
    border-bottom: 1px solid #ddd;
}

.folder-list li a {
    display: block;
    padding: 5px 0;
    color: #666;
}

.folder-list li a:hover {
    color: #333;
    text-decoration: none;
}

.folder-list li i {
    margin-right: 10px;
    width: 16px;
}

.fm-sidebar .tag-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.fm-sidebar .tag-list li {
    float: left;
    margin-right: 5px;
    margin-bottom: 5px;
}

.fm-sidebar .tag-list li a {
    display: block;
    padding: 2px 7px;
    font-size: 11px;
    background: #fcfcfc;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    color: #666;
}

.fm-sidebar .tag-list li a:hover {
    color: #fff;
    text-decoration: none;
    background: #428BCA;
}


/****** 10.3 PROFILE *****/


.profile-name {
    margin: 0;
    margin-bottom: 10px;
    color: #333;
}

.profile-location,
.profile-position {
    font-size: 13px;
    margin-bottom: 3px;
}

.profile-location .fa,
.profile-position .fa {
    width: 16px;
    margin-right: 8px;
    text-align: center;
}

.profile-header {
    margin-bottom: 30px;
}

.profile-header .btn .fa {
    margin-right: 7px;
}

.profile-social-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.profile-social-list li {
    border-bottom: 1px solid #ddd;
    padding: 5px 0;
}

.profile-social-list li:first-child {
    padding-top: 0;
}

.profile-social-list li .fa {
    margin-right: 10px;
    width: 16px;
}

.activity-list {
    padding: 20px 10px;
}

.activity-list .act-media {
    border-bottom: 1px solid #eee;
}

.activity-list .act-thumb {
    width: 28px;
    vertical-align: middle;
}

.activity-list .act-media-body {
    line-height: 16px;
}

.activity-list .media-body p {
    line-height: 21px;
    font-size: 13px;
}

.activity-list .media-title {
    font-family: 'LatoBold';
    font-size: 15px;
    margin-bottom: 0;
}

.activity-list .blog-media {
    padding-bottom: 0;
}

.activity-list .blog-media .media-object {
    width: 120px;
    margin-right: 10px;
    margin-top: 5px;
}

.activity-list .blog-media .media-title {
    margin: 0;
}

.activity-list .img-single img {
    width: 50%;
}

.uploadphoto-list {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 20px;
}

.uploadphoto-list li {
    width: 20%;
    float: left;
}

.uploadphoto-list li a {
    display: block;
    margin-right: 5px;
}

.follower-list {
    padding: 10px;
}

.follower-list .media {
    border-bottom: 1px solid #eee;
}

.follower-list .btn .fa {
    margin-right: 5px;
}

.follower-list .media-object {
    margin-right: 10px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
}

.follower-name {
    color: #333;
    margin: 0;
    font-size: 18px;
    margin-bottom: 5px;
}

.events {
    padding: 10px;
}

.events .subtitle {
    margin-bottom: 20px;
}

.events .media-object {
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
}

.events-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.events-list .event-body {
    font-size: 13px;
}

.event-body small {
    display: block;
}

.event-body small .fa {
    margin-right: 5px;
    width: 14px;
    text-align: center;
}

.event-body p {
    margin-top: 10px;
}

.event-title {
    margin: 0;
    margin-bottom: 5px;
    font-size: 16px;
    font-family: 'LatoBold';
    line-height: normal;
}


/****** 10.4 BLOG LIST ******/


.blog-item {
    background: #fcfcfc;
    margin-bottom: 20px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -moz-box-shadow: 0 3px 0 rgba(12,12,12,0.03);
    -webkit-box-shadow: 0 3px 0 rgba(12,12,12,0.03);
    box-shadow: 0 3px 0 rgba(12,12,12,0.03);
    position: relative;
}

.blog-img img {
    -moz-border-radius: 3px 3px 0 0;
    -webkit-border-radius: 3px 3px 0 0;
    border-radius: 3px 3px 0 0;
}

.blog-details {
    padding: 15px;
}

.blog-title {
    font-family: 'LatoBold';
    margin: 0;
    font-weight: normal;
}

.blog-title a {
    color: #333;
}

.blog-meta {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 10px;
}

.blog-meta::after {
    clear: both;
    display: block;
    content: '';
}

.blog-meta li {
    float: left;
    padding: 0 5px;
    border-right: 1px solid #ddd;
    line-height: normal;
    font-size: 11px;
    margin-bottom: 5px;
}

.blog-meta li:first-child {
    padding-left: 0;
}

.blog-meta li:last-child {
    padding-right: 0;
    border-right: 0;
}

.blog-summary {
    font-size: 13px;
}

.blog-summary p {
    margin: 10px 0;
}

.blog-summary .btn {
    margin-top: 10px;
}

.blog-quote .quote {
    margin: 0;
    font-size: 18px;
    line-height: 24px;
}

.blog-quote .quote a {
    padding: 15px;
    background: #1d2939;
    color: #dadfe6;
    display: block;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    text-align: center;
}

.blog-quote .quote a:hover {
    text-decoration: none;
    background: #2c394c;
}

.blog-quote .quote-author {
    display: block;
    font-family: 'LatoRegular';
    opacity: 0.5;
}

.blog-quote .blog-meta {
    margin-top: 3px;
}

.blog-video {
    -moz-border-radius: 3px 3px 0 0;
    -webkit-border-radius: 3px 3px 0 0;
    border-radius: 3px 3px 0 0;
    overflow: hidden;
    height: 0;
    padding-top: 30px;
    padding-bottom: 56.25%;
    position: relative;
}

.blog-video iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 0;
    top: 0;
    left: 0;
}

.blog-quote .quote-primary a {
    background-color: #428BCA;
}

.blog-quote .quote-primary a:hover {
    background-color: #357EBD;
}

.blog-quote .quote-success a {
    background-color: #1CAF9A;
    color: #fff;
}

.blog-quote .quote-success a:hover {
    background-color: #17A08C;
}

.blog-quote .quote-danger a {
    background-color: #D9534F;
    color: #fff;
}

.blog-quote .quote-danger a:hover {
    background-color: #D43F3A;
}



/***** 10.5 BLOG SINGLE *****/


.panel-blog .blog-img img {
    width: 100%;	
}

.blogsingle-title {
    font-family: 'LatoRegular';
    color: #333;
    margin: 0;
}

.blog-content {
    max-width: 975px;
}

.authorpanel {
    margin: 20px 0;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: 20px 0;
}

.authorpanel::after {
    clear: both;
    display: block;
    content: '';
}

.authorpanel .media {
    padding: 0;
}

.authorpanel .media-object {
    margin-right: 15px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
}

.authorpanel p {
    margin-bottom: 0;
    font-size: 13px;
}

.blog-sidebar {
    font-size: 13px;
    padding-left: 5px;
    margin-top: 5px;
}

.sidebar-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.sidebar-list li {
    display: block;
    border-bottom: 1px solid #ddd;
}

.sidebar-list li a {
    padding: 7px 0;
    display: block;
}

.sidebar-list li i {
    display: inline-block;
    margin-right: 5px;
}

.comment-list .media {
    border-bottom: 1px solid #ddd;
}

.comment-list .media-object {
    width: 60px;
    margin-right: 10px;
}

.comment-list h4 {
    margin: 0;
    color: #333;
    font-size: 16px;
}

.comment-list .media-body {
    font-size: 13px;
    position: relative;
}

.comment-list .reply {
    padding: 3px 8px;
    line-height: normal;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
}

.comment-list .media-body p:last-child {
    margin-bottom: 0;
}

.comment-list .media-body .media:last-child {
    padding-bottom: 0;
    border-bottom: 0;
}


/****** 10.6 PEOPLE DIRECTORY ******/


.letter-list {
    list-style: none;
    padding: 0;
    margin: 0;
    -moz-box-shadow: 0 3px 0 rgba(12,12,12,0.03);
    -webkit-box-shadow: 0 3px 0 rgba(12,12,12,0.03);
    box-shadow: 0 3px 0 rgba(12,12,12,0.03);
}

.letter-list li {
    border-left: 1px solid #eee;
    display: table-cell;
    width: 1%;
}

.letter-list li:first-child {
    border-left: 0;
}

.letter-list li a {
    display: block;
    padding: 8px 0;
    text-align: center;
    text-transform: uppercase;
    background: #f7f7f7;
    -moz-transition: all 0.2s ease-out 0s;
    -webkit-transition: all 0.2s ease-out 0s;
    transition: all 0.2s ease-out 0s;
}

.letter-list li:first-child a {
    -moz-border-radius: 3px 0 0 3px;
    -webkit-border-radius: 3px 0 0 3px;
    border-radius: 3px 0 0 3px;
}

.letter-list li:last-child a {
    -moz-border-radius: 0 3px 3px 0;
    -webkit-border-radius: 0 3px 3px 0;
    border-radius: 0 3px 3px 0;
}

.letter-list li a:hover {
    color: #fff;
    background: #428BCA;
    text-decoration: none;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
}

.people-item {
    background: #fcfcfc;
    padding: 20px;
    font-size: 13px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -moz-box-shadow: 0 3px 0 rgba(12,12,12,0.03);
    -webkit-box-shadow: 0 3px 0 rgba(12,12,12,0.03);
    box-shadow: 0 3px 0 rgba(12,12,12,0.03);
    margin-bottom: 20px;
}

.people-item .media {
    padding: 0;
}

.people-item .media-object {
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    margin-right: 10px;
    width: 110px;
    height: 110px;
}

.people-item .fa {
    margin-right: 5px;
}

.person-name {
    margin: 0 0 5px 0;
    color: #428BCA;
}

.people-item .social-list {
    margin-top: 10px;
    margin-bottom: 0;
    list-style: none;
    padding: 0;
}

.people-item .social-list::after {
    clear: both;
    display: block;
    content: '';
}

.people-item .social-list li {
    float: left;
    margin-right: 5px;
    margin-bottom: 5px;
}

.people-item .social-list li a {
    font-size: 16px;
    border: 1px solid #ddd;
    padding: 3px 5px;
    width: 30px;
    display: block;
    text-align: center;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    color: #666;
}

.people-item .social-list li a:hover {
    background-color: #eee;
    color: #333;
}

.people-item .social-list li a .fa {
    margin-right: 0;
}


/***** 10.7 NOT FOUND PAGE *****/


.notfoundpanel {
    text-align: center;
    margin-top: 10%;
    width: 600px;
    margin: 10% auto 0 auto;
}

.notfoundpanel h1 {
    font-size: 130px;
    font-family: 'LatoBold', sans-serif;
    color: #333;
    margin: 0;
    line-height: 130px;
}

.notfoundpanel h3 {
    color: #333;
    margin: 0;
}

.notfound h4 {
    font-size: 14px;
}

.notfoundpanel form {
    width: 350px;
    margin: 20px auto 0 auto;
}

.notfoundpanel .form-control {
    width: 250px;
    float: left;
}


/***** 10.8 SEARCH RESULTS *****/


.nav-sr li {
    border-bottom: 1px solid #ddd;  
}

.nav-sr li a {
    padding: 5px 0;
    color: #555;
}

.nav-sr li a:hover {
    background: none;
    color: #333;
}

.nav-sr li a i {
    margin-right: 5px;
}

.results-list .media {
    border-bottom: 1px solid #eee;
}

.results-list .media-object {
    width: 125px;
    margin-right: 10px;
}

.results-list .filename {
    margin: 0;
    font-size: 16px;
}

.results-list .media-body {
    line-height: 18px;
}



/***** 10.9 INVOICE *****/


.table-invoice {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-bottom: 1px solid #eee;
    margin-top: 20px;
}

.table-invoice thead > tr > th {
    background: none;
}

.table-invoice thead > tr > th:last-child,
.table-invoice thead > tr > th:nth-child(3),
.table-invoice thead > tr > th:nth-child(2) {
    text-align: right;
}

.table-invoice tbody > tr > td:last-child,
.table-invoice tbody > tr > td:nth-child(3),
.table-invoice tbody > tr > td:nth-child(2) {
    width: 15%;
    text-align: right;
}

.table-total {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    float: right;
}

.table-total > tbody > tr > td {
    border: 0;
}

.table-total > tbody > tr > td:last-child {
    border-bottom: 1px solid #ddd;
    width: 15%;
    text-align: right;
}

.table-total > tbody > tr > td:first-child {
    text-align: right;
    color: #333;
}



/****** 10.10. TIMELINE ******/


.timeline-post .panel-body {
    padding: 0;
}

.timeline-post .form-control {
    border: 0;
    -moz-border-radius: 3px 3px 0 0;
    -webkit-border-radius: 3px 3px 0 0;
    border-radius: 3px 3px 0 0;
    resize: vertical;
}

.timeline-post .form-control:focus {
    -moz-box-shdow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.timeline-post .panel-footer {
    padding: 10px;
    background: #f7f7f7;
}

.timeline-btns {
    padding: 5px;
}

.timeline-btns a {
    display: inline-block;
    font-size: 14px;
    width: 20px;
    height: 20px;
    color: #ccc;
    text-align: center;
    margin-right: 5px;
}

.timeline-btns a:hover {
    color: #666;
}

.panel-timeline .panel-heading {
    padding: 10px 10px 6px 10px;
    border-bottom: 0;
}

.panel-timeline .panel-body {
    padding: 0 10px;   
}

.panel-timeline .panel-heading .media {
    margin: 0;
    padding: 0;
}

.panel-timeline .panel-heading .media-object,
.panel-timeline .panel-footer .media-object {
    width: 32px;
    border-radius: 1px;
}

.panel-timeline .panel-body .media {
    margin-top: 10px;
    padding-bottom: 10px;
}

.panel-timeline .panel-body .media-object {
    width: 150px;
}

.panel-timeline .panel-body h4 {
    font-size: 16px;
    margin-bottom: 5px;
    font-family: 'LatoBold', sans-serif;
}

.panel-timeline h4 {
    margin: 0;
    font-size: 13px;
    line-height: normal;
}

.panel-timeline .panel-footer {
    padding: 10px;
}

.panel-timeline .panel-footer .form-control {
    padding: 8px 10px;
    border-radius: 0;
}

.timeline-btns {
    padding: 10px;
}

.timeline-btns:after {
    clear: both;
    display: block;
    content: '';
}

.timeline-video {
    -moz-border-radius: 3px 3px 0 0;
    -webkit-border-radius: 3px 3px 0 0;
    border-radius: 3px 3px 0 0;
    overflow: hidden;
    height: 0;
    padding-top: 30px;
    padding-bottom: 56.25%;
    position: relative;
}

.timeline-video iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 0;
    top: 0;
    left: 0;
}



/***** 10.11 LOCKED SCREEN *****/


.lockedpanel {
    width: 250px;
    margin: 10% auto 0 auto;
    text-align: center;
}

.lockedpanel .loginuser {
    text-align: center;
}

.lockedpanel .loginuser img {
    -moz-border-radius: 100px;
    -webkit-border-radius: 100px;
    border-radius: 100px;
    background: rgba(255,255,255,0.4);
    padding: 5px;
}

.lockedpanel .locked {
    font-size: 42px;
    margin-bottom: 20px;
}

.lockedpanel .logged {
    margin-top: 20px;
}

.lockedpanel .logged h4 {
    margin: 0;
    font-size: 21px;
    color: #333;
}

.lockedpanel form {
    margin-top: 20px;
}

.lockedpanel form .btn {
    display: block;
    margin-top: 10px;
}



/***** 10.12 SIGN IN *****/


.signinpanel {
    width: 780px;
    margin: 10% auto 0 auto;
}

.signinpanel .logopanel {
    float: none;
    width: auto;
    padding: 0;
    background: none;
}

.signinpanel .signin-info ul {
    list-style: none;
    padding: 0;
    margin: 20px 0;
}

.signinpanel .form-control {
    display: block;
    margin-top: 15px;
}
/*
.signinpanel .uname {
    background: #fff url(../images/user.png) no-repeat 95% center; 
}

.signinpanel .pword {
    background: #fff url(../images/locked.png) no-repeat 95% center; 
}*/

.signinpanel .btn {
    margin-top: 15px;
}

.signinpanel form {
    background: rgba(255,255,255,0.2);
    border: 1px solid #ccc;
    -moz-box-shadow: 0 3px 0 rgba(12,12,12,0.03);
    -webkit-box-shadow: 0 3px 0 rgba(12,12,12,0.03);
    box-shadow: 0 3px 0 rgba(12,12,12,0.03);
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    padding: 30px;
}

.signinpanel form a {
    color: #666;
}


/***** 10.13 SIGN UP *****/


.signuppanel {
    margin: 5% auto 0 auto;
    width: 780px;
}

.signuppanel .row {
    margin-left: -5px;
    margin-right: -5px;
}

.signuppanel .logopanel {
    float: none;
    width: auto;
    padding: 0;
    background: none;
}

.signuppanel .col-sm-6,
.signuppanel .col-sm-5,
.signuppanel .col-sm-3,
.signuppanel .col-sm-4 {
    padding-left: 5px;
    padding-right: 5px;
}

.signuppanel .signup-info {
    padding-right: 20px;
}

.signuppanel form {
    background: rgba(255,255,255,0.2);
    border: 1px solid #ccc;
    -moz-box-shadow: 0 3px 0 rgba(12,12,12,0.03);
    -webkit-box-shadow: 0 3px 0 rgba(12,12,12,0.03);
    box-shadow: 0 3px 0 rgba(12,12,12,0.03);
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    padding: 30px;
}

.feat-list {
    margin-top: 20px;
}

.feat-list .fa {
    font-size: 20px;
    border: 2px solid #636e7b;
    padding: 4px 5px;
    -moz-border-radius: 100px;
    -webkit-border-radius: 100px;
    border-radius: 100px;
    float: left;
}

.feat-list h4 {
    margin-left: 45px;
    padding-top: 3px;
}

.feat-list p {
    margin-left: 45px;
}

.signup-footer {
    border-top: 1px solid #ddd;
    margin-top: 30px;
    padding-top: 10px;
    font-size: 12px;
}




/********** 11. LEFT PANEL COLLAPSED **********/
/**********************************************/



.leftpanel-collapsed .logopanel {
    display: none;
}

.leftpanel-collapsed .headerbar {
    margin-left: -50px;
}

.leftpanel-collapsed .leftpanel {
    width: 50px;
    top: 50px;
}

.leftpanel-collapsed .leftpanelinner {
    padding: 0;
}

.leftpanel-collapsed .leftpanelinner .sidebartitle {
    display: none;
}

.leftpanel-collapsed .nav-bracket {
    margin: 5px;   
}

.leftpanel-collapsed .nav-bracket li a {
    text-align: center;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    padding: 10px;
    position: relative;
}

.leftpanel-collapsed .nav-bracket > li > a:hover {
    border-radius: 2px 0 0 2px;
}

.leftpanel-collapsed .nav-bracket > li.nav-parent > a {
    background-image: none;
}

.leftpanel-collapsed .nav-bracket > li > a > span:last-child {
    position: absolute;
    background: #fff;
    padding: 10px 10px 10px 5px;
    left: 40px;
    top: 0;
    min-width: 209px;
    text-align: left;
    z-index: 100;
    display: none;
    -moz-box-shadow: 0 3px 0 rgba(12,12,12,0.03);
    -webkit-box-shadow: 0 3px 0 rgba(12,12,12,0.03);
    box-shadow: 0 3px 0 rgba(12,12,12,0.03);
    -moz-border-radius: 0 2px 2px 0;
    -webkit-border-radius: 0 2px 2px 0;
    border-radius: 0 2px 2px 0;
}

.leftpanel-collapsed .nav-bracket > li > a .badge {
    display: none;
}

.leftpanel-collapsed .nav-bracket > li.active > a > span:last-child {
    -moz-border-radius: 0 2px 0 0;
    -webkit-border-radius: 0 2px 0 0;
    border-radius: 0 2px 0 0;
}

.leftpanel-collapsed .nav-bracket ul {
    display: none;
}

.leftpanel-collapsed .nav-bracket > li.nav-hover > a {
    background: #fff;
    color: #1d2939;
}

.leftpanel-collapsed .nav-bracket > li.nav-hover.active > a {
    background: #1CAF9A;
    color: #fff;
}

.leftpanel-collapsed .nav-bracket > li.nav-hover > a > span:last-child {
    display: block;
}

.leftpanel-collapsed .nav-bracket > li.nav-hover.active > a > span:last-child {
    background: #1CAF9A;
    color: #fff;
}

.leftpanel-collapsed .nav-bracket li.nav-hover ul {
    display: block;
    position: absolute;
    top: 41px;
    left: 45px;
    margin: 0;
    min-width: 204px;
    background: #1d2939;
    z-index: 100;
    -moz-border-radius: 0 0 2px 0;
    -webkit-border-radius: 0 0 2px 0;
    border-radius: 0 0 2px 0;
}

.leftpanel-collapsed .nav-bracket ul a {
    text-align: left;
    padding: 6px 10px;
    padding-left: 0;
}

.leftpanel-collapsed .nav-bracket ul a:hover {
    background: none;
}

.leftpanel-collapsed .nav-bracket li a i {
    margin-right: 0;
}

.leftpanel-collapsed .mainpanel {
    margin-left: 50px;
}

.leftpanel-collapsed .infosummary {
    display: none;
}

.leftpanel-collapsed .sticky-leftpanel {
    overflow-y: visible;
}

.leftpanel-collapsed .nav-bracket .children {
    display: none;
}




/********** 12. RIGHT PANEL STYLES **********/
/********************************************/


.chat-view {
    margin-right: 240px;
}

.rightpanel {
    width: 240px;
    height: 100%;
    position: fixed;
    overflow-y: auto;
    overflow-x: hidden;
    top: 0;
    right: -240px;
    display: none;
}

.chat-view .rightpanel {
    display: block;
    right: 0;
}

.chat-relative-view {
    right: 240px;
}

.chat-relative-view .rightpanel {
    display: block;
    right: 0;
}

.rightpanel,
.rightpanel label {
    color: #999;
}

.rightpanel .nav-tabs {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    background: #131d29;
    position: fixed;
    width: 240px;
}

.rightpanel .nav-tabs > li {
    display: table-cell;
    width: 1%;
}

.rightpanel .nav-tabs > li > a {
    padding: 15px 20px 16px 20px;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    color: rgba(255,255,255,0.5);
}

.rightpanel .nav-tabs > .active > a,
.rightpanel .nav-tabs > .active > a:hover,
.rightpanel .nav-tabs > .active > a:focus {
    background: #1D2939;
    color: #fff;
}

.rightpanel .tab-content {
    background: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-top: 45px;
}

.chatuserlist {
    list-style: none;
    padding: 0;
    border-bottom: 1px dotted rgba(255,255,255,0.05);
}

.chatuserlist li {
    border-top: 1px dotted rgba(255,255,255,0.05);
}

.chatuserlist .media-thumb {
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    border: 2px solid #999;
    padding: 3px;
    display: block;
}

.chatuserlist .media {
    margin: 10px 0;
    padding: 0;
}

.chatuserlist .online .media-thumb {
    border-color: #1CAF9A;
}

.chatuserlist .media-object {
    width: 30px;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
}

.chatuserlist .media-body {
    line-height: normal;
    padding-top: 7px;
}

.chatuserlist .media-body small {
    color: #666;
    display: block;
}

.chatuserlist .media-body strong {
    color: #bbb;
    font-size: 13px;
    margin-bottom: 4px;
    display: block;
}

.chatuserlist .badge {
    margin-top: 5px;
}

.rightpanel .pane-settings {
    font-size: 13px;
}


/********** 13. HORIZONTAL MENU **********/
/*****************************************/


.horizontal-menu {
    margin: 0;   
}

.horizontal-menu .menutoggle {
    display: none;
}

.horizontal-menu .leftpanel {
    display: none;
}

.horizontal-menu .mainpanel {
    margin: 0;
    width: 100%;
    overflow: hidden;
}

.horizontal-menu.chat-view .mainpanel {
    margin-left: -240px
}

.headerbar .logopanel {
    float: left;
    width: 240px;
    border-right: 1px solid #eee;
}

.horizontal-menu.chat-view .navhor-bracket {
    padding: 0;
}

.headerbar .topnav {
    float: left;
    margin: 0;
}

.headerbar .topnav > ul {
    padding: 0;
    margin: 0;
    float: left;
}

.headerbar .topnav > ul > li {
    margin: 0;
    float: left;
}

.headerbar .topnav > ul > li > a {
    padding: 15px 15px 12px 15px;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    text-transform: uppercase;
    font-size: 12px;
    color: #666;
    font-family: 'LatoBold', sans-serif;
}

.headerbar .topnav > ul > li > a:hover,
.headerbar .topnav > ul > li > a:focus,
.headerbar .topnav > ul > li.open > a,
.headerbar .topnav > ul > li.active > a {
    box-shadow: 0 3px 0 #1CAF9A;
    color: #1CAF9A;
    background: none;
}

.headerbar .topnav > ul > li > a i {
    margin-right: 5px;
}

.headerbar .topnav > ul > li > a .badge {
    margin-left: 10px;
}

.headerbar .topnav > ul > li .dropdown-menu {
    margin-top: 2px;
    -moz-border-radius: 0 0 3px 3px;
    -webkit-border-radius: 0 0 3px 3px;
    border-radius: 0 0 3px 3px;
}

.headerbar .topnav > ul > li .dropdown-menu > li > a {
    padding: 6px 10px;
    font-size: 13px;
}

.headerbar .topnav > ul > li .dropdown-menu > li > a:hover {
    background: #1CAF9A;
    color: #fff;
}

.headerbar .topnav > ul > li .dropdown-menu i {
    display: none;
}




/********** 14. FIXED WIDTH **********/
/*************************************/



.fixed {
    background: #c9cccf;
}

.fixed section {
    margin: auto;
    max-width: 1050px;
    position: relative;
    background: #1D2939;
}

.fixed .rightpanel {
    background: #1D2939;
}

.fixed-noleft .leftpanel {
    display: none;
}

.fixed-noleft .mainpanel {
    margin-left: 0;
}

.fixed-noleft.chat-view .headerbar {
    margin-left: 0;
}

.fixed-noleft.chat-view .logopanel {
    display: block;
}

.fixed-noleft .menutoggle {
    display: none;
}



/********** 15. MODALS **********/
/********************************/


.bs-example-modal-panel .modal-content {
    background: none;
}

.modal-photo-viewer {
    margin-top: 0;
    margin-bottom: 0;
    top: 50%;
    left: 50%;
    width: auto;
    position: absolute;
}

.modal-photo-viewer .modal-content {
    position: relative;
    width: 900px;
    height: 600px;
    margin-left: -50%;
    margin-top: -300px;
}

.modal-photo-viewer .modal-photo-left,
.modal-photo-viewer .modal-photo-right {
    padding: 0;
}

.modal-photo-viewer .modal-photo-left {
    background: #000;
}

.modal-photo-viewer .modal-photo {
    top: 50%;
    position: relative;
}

.modal-photo-viewer .row {
    margin-left: 0;
    margin-right: 0;
}

.modal-photo-viewer .img-responsive {
    max-height: 600px;
    margin: auto;
}

.media-details {
    padding: 20px;
}

.media-details .categ {
    font-size: 11px;
}

.media-details .media-title {
    margin: 0;
    margin-bottom: 10px;
    color: #333;
    font-size: 20px;
}

.media-details p {
    font-size: 13px;
}

.media-details .details {
    margin-top: 20px;
}

.media-details .details h4 {
    font-size: 12px;
    color: #333;
    text-transform: uppercase;
    font-family: 'LatoBold';
}

.media-details .table {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.media-details .table tr td {
    padding: 3px 0;
    font-size: 13px;
    background: none;
}



/********** 16. BUG TRACKER **********/
/*************************************/

.panel .panel-table {
    padding: 0;
}

.panel-table .table {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin: 0;
}

.table-buglist thead tr th:first-child,
.table-buglist tbody tr td:first-child {
    text-align: center;
    width: 30px;
}

.table-buglist .fa {
    cursor: pointer;    
}

.table-buglist .fa-bug {
    color: #D9534F;
}

.table-buglist .fa-magic {
    color: #1CAF9A;
}

.table-buglist .btn-group {
    margin: 0;
}

.table-buglist .btn-group .dropdown-toggle {
    color: #999;
    cursor: pointer;
}

.table-buglist .btn-group .dropdown-toggle:hover {
    color: #666;
}

.bug-key-title {
    margin: 0;
    margin-bottom: 5px;
}



/********** 17. ADDITIONAL STYLES **********/
/*******************************************/


/***** 17.1 DROPDOWN *****/

.dropdown-menu-head {
    background: #fff
}

.dropdown-menu-head .title {
    background: #1d2939;
    color: #94989d;
    padding: 15px;
    text-transform: uppercase;
    font-size: 11px;
    margin: 0;
}


/***** 17.2 ICON *****/


.tp-icon {
    padding: 10px 20px;
    line-height: 20px;
    border-color: #ddd;
    position: relative;
}

.tp-icon .badge {
    position: absolute;
    font-size: 10px;
    font-weight: normal;
    right: 4px;
    top: 10px;
    line-height: 13px;
    background-color: #D9534F;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    padding: 2px 5px;
}


/***** 17.3 TIME PICKER *****/


.bootstrap-timepicker-widget.dropdown-menu {
    width: 230px;
}

/***** 17.4 COLOR PICKER *****/

.colorpicker-input {
    width: 200px;
    float: left;
}

.colorselector {
    margin-top: 4px;
}

/***** 17.5 STICKY HEADER */

.stickyheader .logopanel {
    position: fixed;
    top: 0;
    left: 0;
    width: 240px;
    z-index: 100;
}

.stickyheader .leftpanel {
    top: 50px;
}

.stickyheader .sticky-leftpanel {
    top: 0;
}

.stickyheader .sticky-leftpanel .leftpanelinner {
    margin-top: 55px;
}

.stickyheader .headerbar {
    position: fixed;
    top: 0;
    left: 240px;
    width: 100%;
    z-index: 100;
    box-shadow: 0 1px rgba(0,0,0,0.05);
}

.stickyheader .mainpanel {
    padding-top: 50px;
}

.stickyheader .header-right {
    margin-right: 240px;
}

.stickyheader.leftpanel-collapsed .headerbar {
    left: 50px;   
}

.stickyheader.leftpanel-collapsed .header-right {
    margin-right: 0;
}

.stickyheader.chat-view .headerbar {
    right: 240px;
    width: auto;
}


/***** 17.6 CUSTOM STYLES *****/

.mb5 {
    margin-bottom: 5px;
}

.mb10 {
    margin-bottom: 10px;
}

.mb15 {
    margin-bottom: 15px;
}

.mb20 {
    margin-bottom: 20px;
}

.mb30 {
    margin-bottom: 30px;
}

.mb40 {
    margin-bottom: 40px;
}

.mr5 {
    margin-right: 5px;
}

.mr10 {
    margin-right: 10px;
}

.mr20 {
    margin-right: 20px;
}

.mr30 {
    margin-right: 30px;
}

.mt5 {
    margin-top: 5px;
}

.mt10 {
    margin-top: 10px;
}

.nomargin {
    margin: 0;
}

.noshadow {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.border-right-0 {
    border-right: 0;
}

.border-left-0 {
    border-left: 0;
}

.nopadding {
    padding: 0 !important;
}

.padding-top-0 {
    padding-top: 0;
}

.padding5 {
    padding: 5px;
}

.padding10 {
    padding: 10px;
}

.serif {
    font-family: 'Georgia, serif';
}

.italic {
    font-style: italic;
}

.dark {
    color: #333;
}

.width100p {
    width: 100%;
}

.width20 {
    width: 20px;
}

.width30 {
    width: 30px;
}

.inblock {
    display: inline-block;
}



/********** 18. MEDIA QUERIES **********/
/***************************************/


@media screen and (max-width: 1024px) {

    .menutoggle{
        display: inline;
    }
    body {
        position: relative;
    }

    .leftpanel {
        display: none;
    }

    .mainpanel {
        margin-left: 0;
        width: 100%;
    }

    .leftpanel-collapsed .leftpanel {
        display: none;
    }

    .leftpanel-collapsed .mainpanel {
        margin-left: 0;
    }

    .leftpanel-collapsed .headerbar {
        margin-left: 0;
    }

    .leftpanel-show section {
        overflow-x: hidden;
    }

    .leftpanel-show .leftpanel {
        display: block;
    }

    .leftpanel-show .mainpanel {
        margin-left: 240px;
    }

    .stickyheader .headerbar {
        left: 0;
    }

    .stickyheader.leftpanel-show .headerbar {
        left: 240px;
    }

    .stickyheader.chat-relative-view .headerbar {
        right: 240px;
        left: auto;
    }

    .stickyheader .header-right {
        margin-right: 0;
    }

    .btn-demo {
        margin-bottom: 20px;
    }

    .leftpanelinner {
        padding: 5px 10px;
    }

    .leftpanelinner .searchform input {
        background: #131b26;
        border: 0;
    }

    /* horizontal menu related */
    .nav-horizontal {
        position: relative;
    }

    .horizontal-menu .menutoggle {
        display: block;
    }

    .horizontal-menu .headerbar .logopanel {
        display: none;
    }

    .nav-horizontal .caret {
        display: none;
    }

    .horizontal-menu .header-left,
    .horizontal-menu .topnav {
        float: none;
    }

    .horizontal-menu-sidebar .header-left,
    .horizontal-menu-sidebar .topnav {
        float: none;
    }

    .fixed-noleft .menutoggle {
        display: block;
    }

    .fixed-noleft .headerbar .logopanel {
        display: none;
    }

    .fixed-noleft .header-left,
    .fixed-noleft .topnav {
        float: none;
    }

}

@media screen and (max-width: 767px) {

    .searchform {
        position: relative;
    }

    .headermenu li:nth-child(4) {
        display: none;
    }

    .leftpanel .searchform,
    .leftpanel .userlogged {
        display: block;
    }

    .breadcrumb-wrapper {
        display: none;
    }

    .headermenu .dropdown-menu:after {
        display: none;
    }

    .rightpanel .nav-tabs.nav-justified > li > a,
    .rightpanel .nav-justified > li > a {
        margin-bottom: 0;
    }

    .panel-email .col-sm-3 {
        display: none;
    }
}

@media screen and (max-width: 640px) {

    .notfoundpanel {
        width: auto;
        padding: 0 20px;
    }

    .notfoundpanel h1 {
        font-size: 72px;
        line-height: 72px;
    }

    .notfoundpanel h3 {
        font-size: 14px;
        line-height: normal;
    }

    .notfound h4 {
        font-size: 12px;
        line-height: 18px;
    }

    .notfoundpanel form {
        width: auto;
    }

    .notfoundpanel .form-control {
        float: none;
        width: 100%;
    }

    .notfoundpanel .btn {
        margin-top: 10px;
        width: 100%;
    }

    .signinpanel,
    .signuppanel {
        margin: 0 auto;
        width: 480px;
        padding: 20px;
    }

    .signinpanel form {
        margin-top: 20px;
    }

    .signup-footer {
        margin-bottom: 10px;
    }

    .signuppanel .form-control {
        margin-bottom: 10px;
    }

    .signup-footer .pull-left,
    .signup-footer .pull-right {
        float: none !important;
        text-align: center;
    }

    .widget-quick-status-post .nav-tabs.nav-justified > li {
        display: table-cell;
        width: 1%;
        margin-bottom: -5px;
    }

    .widget-quick-status-post .nav-tabs.nav-justified > li a {
        margin: 0;
    }

    .nav-tabs.nav-justified.nav-profile > li {
        float: none;
        margin-bottom: 0;
        display: table-cell;
        width: 1%;
    }

    .nav-tabs.nav-justified.nav-profile > li a {
        margin: 0;
        -moz-border-radius: 3px 3px 0 0;
        -webkit-border-radius: 3px 3px 0 0;
        border-radius: 3px 3px 0 0;

    }

    .dataTables_info {
        float: none !important;
        text-align: center;
        margin-bottom: 10px;
    }

    .dataTables_paginate {
        margin-bottom: 10px;
        text-align: center !important;
        float: none !important;
    }

    .paginate_disabled_previous,
    .paginate_enabled_previous,
    .paginate_disabled_next,
    .paginate_enabled_next {
        float: none !important;
    }

    .dataTables_filter,
    .dataTables_length {
        float: none !important;
        padding: 10px 0 0 10px;
        margin: auto !important;
        display: inline-block;
    }

    .table.dataTable th,
    .table.dataTable td {
        font-size: 13px;
        min-width: 150px;
    }

    .paging_full_numbers {
        min-width: 500px;
        margin: 0 0 10px 10px;
    }

    .paging_full_numbers:after {
        clear: both;
        display: block;
        content: '';
    }

}

@media screen and (max-width: 480px) {

    .signinpanel,
    .signuppanel {
        width: 360px;
    }

    .panel-email .pull-right {
        float: none !important;
    }

    .pageheader h2 {
        font-size: 18px;
    }

    .pageheader .glyphicon,
    .pageheader .fa {
        font-size: 18px;
    }

    .pageheader h2 span {
        font-size: 11px;
    }

    .fc-header td {
        display: block !important;
        text-align: center;
        width: 100%;
        border: 1px solid #eee;
        padding: 10px !important;
        margin-top: -1px;
    }

    .fc-button {
        padding: 2px 5px !important;
        margin: 0 !important;
    }

    .fc-header-title h2 {
        margin: 0 !important;
    }

    .filemanager-options li {
        padding: 5px 7px;
    }

    .filemanager-options .filter-type {
        display: block;
        float: none;
        border-top: 1px solid #eee;
    }

    .comment-list .media-object {
        width: 30px;
        padding: 2px;
    }

    .comment-list .media > .pull-left {
        margin: 0;
    }

    .comment-list h4 {
        font-size: 13px;
    }

    .comment-list .reply {
        font-size: 10px;
        padding: 2px 5px;
    }

    .comment-list .media-body {
        font-size: 12px;
        line-height: 18px;
    }

    .blog-content form {
        margin-bottom: 20px;
    }

    .letter-list {
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .letter-list::after {
        clear: both;
        display: block;
        content: '';
    }

    .letter-list li {
        float: left;
        width: auto;
        display: inline-block;
    }

    .letter-list li a {
        display: block;
        padding: 2px 5px;
    }

    .btn-invoice:before {
        display: block;
        content: '';
        clear: both;
    }

    .btn-invoice .btn {
        display: block;
        width: 100%;
        margin-top: 10px;
    }

    .nav-tabs.nav-justified.nav-profile > li a {
        font-size: 13px;
        padding: 14px 10px;
    }

    #bloglist > div {
        padding: 0;
        margin: 0;
        float: none;
        width: auto;
    }

}

@media screen and (max-width: 360px) {

    .pageheader h2 span {
        display: none;
    }

    .blog-title {
        font-size: 14px;
        line-height: 18px;
    }

    .blog-summary {
        font-size: 12px;
        line-height: 18px;
    }

    .blog-summary .btn {
        padding: 2px 5px;
        display: block;
        width: 100%;
    }

    .headermenu li:first-child .dropdown-menu {
        margin-right: -130px;
    }

    .headermenu li:nth-child(2) .dropdown-menu {
        margin-right: -85px;
    }

    .headermenu li:nth-child(3) .dropdown-menu {
        margin-right: -37px;
    }

    .nav-tabs.nav-justified.nav-profile > li {
        float: none;
        display: block;
        width: auto;
    }

    .blog-media .media-body {
        overflow: visible;
    }

    .follower-list .btn {
        display: block;
        margin: 0 0 5px 0;
        width: 100%;
    }

    .filemanager-options li {
        display: block;
        border-top: 1px solid #eee;
    }

    .filemanager-options li:first-child {
        border-top: 0;
    }

    .authorpanel .media-object {
        width: 80px;
        margin-right: 10px;
    }

    .people-item .media-object {
        width: 80px !important;
        height: 80px !important;
    }

    .pagination {
        margin-bottom: 20px;
    }

}

@media screen and (max-width: 320px) {

    .signinpanel,
    .signuppanel {
        width: 310px;
    }

}

.login-title-center{
    text-align: center;
    color: #fff;
}

.login-center{
    border: 1px solid #888;
    padding: 20px;
    border-radius: 5px;
}

.fa {
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 20px;
}

.breadcrumb-layout{
    margin: 20px;
    background-color: transparent;
}
.site-layout-background {
    background: #F4F4F4;
    padding: 24px;
    margin: 0;
    min-height: 280px;
}
.site-page-header-ghost-wrapper {
    background: #F4F4F4;
}

.site-page-header {
    border: 1px solid rgb(235, 237, 240);
}

.site-homepage-card {
    background: #F4F4F4;
}

.site-calendar-demo-card {
    border: 1px solid #f0f0f0;
    border-radius: 2px;
}
.site-des-background {
    background: #FFF;
    padding: 24px;
    margin: 0;
    min-height: 280px;
    border: 1px solid #f0f0f0;
    border-radius: 2px;
}
.background-seal {
    background-image: url('/src/img/seal.png');
    background-size: cover;
    background-position: right;
    width: 200px;
    height: 200px;
    margin-left: 100px;
    top: 400px;
    position: absolute;
}
